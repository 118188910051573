export default {
    'HeaderNav1': '骑',
    'HeaderNav2': '愿景',
    'HeaderNav3': '帮助',
    'HeaderNav4': '博客',
    'HeaderText1': '下载这个软件',
    'HeaderText3': '回到首页',
    'HeaderText2': '获取 HRT 应用程序',
    'LangChoose': '选择语言',
    'SectionOneTitle': '让我们重新构想我们的城市！',
    'SectionOneText': '获取HRT应用程序',
    'SectionOneTip': '您的浏览器不支持视频标记。',
    'SectionTwoTitle': '游览城镇的更智能方式',
    'SectionTwoText': '只需轻按手机即可从 HRT 租用电动滑板车或电动自行车，几分钟内即可到达城市的任何地方。这是探索新地方或只是从不同角度体验自己家乡的完美方式。',
    'SectionTwoBtn': '怎么运行的',
    'SectionThreeTitle1': '共同走向',
    'SectionThreeTitle2': '更美好的明天',
    'SectionThreeItemOneTitle1': '我们教育',
    'SectionThreeItemOneTitle2': '我们的用户',
    'SectionThreeItemOneText': '知识是确保安全和可持续交通环境的关键',
    'SectionThreeItemTwoTitle1': '我们与城市',
    'SectionThreeItemTwoTitle2': '合作',
    'SectionThreeItemTwoText': '因为只有当我们共同努力时，真正、持久的改变才会发生',
    'SectionThreeItemThreeTitle1': '我们',
    'SectionThreeItemThreeTitle2': '与公共交通相结合',
    'SectionThreeItemThreeText': '我们的服务与更广泛的交通生态系统协同工作',
    'SectionFourTitle': '为居住而生的城市',
    'SectionFourText': '在 HRT，我们将人们重新置于城市转型的中心。我们的愿景是未来城市居民在安全、健康、污染更少、噪音更少、压力更小的环境中生活和出行。',
    'SectionFourBtn': '了解我们的愿景',
    'SectionFiveTitle': '最新消息',
    'SectionFiveItemOneTitle': 'HRT 发布安全报告，向城市传递核心信息：让我们共同努力，把事情做好',
    'SectionFiveItemTwoTitle': 'HRT 电动滑板车停车指南',
    'SectionFiveItemThreeTitle': 'HRT 的气候目标已获得基于科学的目标倡议的批准',
    'FooterItemOneTitle': '骑手',
    'FooterItemOneText1': '怎么运行的',
    'FooterItemOneText2': '安全骑行',
    'FooterItemOneText3': '定价和通行证',
    'FooterItemOneText4': '如何停车',
    'FooterItemOneText5': '帮助中心',
    'FooterItemTwoTitle': '城市及合作伙伴',
    'FooterItemTwoText1': '城市产品',
    'FooterItemTwoText2': '我们的方法',
    'FooterItemTwoText3': '交通安全',
    'FooterItemTwoText4': '停车解决方案',
    'FooterItemTwoText5': '移动合作伙伴',
    'FooterItemThreeTitle': '公司',
    'FooterItemThreeText1': '关于我们',
    'FooterItemThreeText2': '地点',
    'FooterItemThreeText3': '可持续发展',
    'FooterItemThreeText4': '职业机会',
    'FooterItemThreeText5': '按',
    'FooterItemFourTitle': '资源',
    'FooterItemFourText1': '使用条款',
    'FooterItemFourText2': '隐私政策',
    'FooterItemFourText3': '饼干',
    'FooterItemFourText4': '刊物',
    'FooterItemFourText5': '现代奴隶制声明',
    'FooterDesign': '由瑞典 HRT 设计',
    'chooseLanguage': '选择语言',
    'rideFirstModuleTitle': '怎么运行的',
    'rideFirstModuleText': '在城镇中移动从未如此简单或方便。了解如何通过几个简单的步骤开始滚动并在几分钟内到达目的地。下载这个软件',
    'rideSecondModuleTitle': '立即开始：',
    'rideSecondModuleText1-1': '下载应用程序',
    'rideSecondModuleText1-2': '免费',
    'rideSecondModuleText1-3': '并注册一个帐户。',
    'rideSecondModuleText2-1': '使用应用内地图',
    'rideSecondModuleText2-2': '查找附近的电动滑板车或电动自行车。',
    'rideSecondModuleText3-1': '扫描',
    'rideSecondModuleText3-2': '车把上的二维码解锁。',
    'rideSecondModuleText4-1': '搭乘巴士，',
    'rideSecondModuleText4-2': '只需几分钟即可到达城市的任何地方。',
    'rideThreeModuleTitle': '电动滑板车还是电动自行车？',
    'rideThreeModuleText1': '当您需要快速到达较短距离的某个地方时，我们的电动滑板车非常适合，而我们的电动自行车则非常适合较长的路线。',
    'rideThreeModuleText2': '如果您是电动滑板车新手，请在应用程序中激活减速模式。这限制了踏板车的最大速度，让您在学习操作车辆时可以缓慢起步。',
    'rideFourModuleTitle': '谁可以骑电动滑板车？',
    'rideFourModuleText1': '您必须年满 18 岁并持有有效的驾驶执照才能在美国租用电动滑板车。',
    'rideFourModuleText2': '因此，注册后，您需要上传驾驶执照照片和自拍照来验证您的身份。',
    'rideFiveModuleTitle': '骑行区',
    'rideFiveModuleText': '我们的车辆根据其位置响应预设规则。所有可使用 HRT 的城市都被划分为具有不同规定的虚拟区域。出发前请务必熟悉每一项。',
    'rideSixModuleTitle1': '停车区 +',
    'rideSixModuleText1': '这是一个停车的好地方。事实上，太好了，我们会给您折扣，以感谢您在这里停车。',
    'rideSixModuleTitle2': '停车区',
    'rideSixModuleText2': '在某些城市，仅允许在指定停车区停车。出发前，使用地图查找目的地附近的停车区。',
    'rideSixModuleTitle3': '禁止停车区',
    'rideSixModuleText3': '有些地方根本不适合停车。这就是为什么我们设有禁止停车区，您将无法结束旅程。',
    'rideSevenModuleTitle1': '低速区',
    'rideSevenModuleText1': '低速区域是通常挤满汽车或行人的区域。因此，车辆在这里会自动减速一点。',
    'rideSevenModuleTitle2': '禁止骑行区',
    'rideSevenModuleText2': '顾名思义，这是所有 HRT 车辆的禁区。在这里，您将无法骑乘或停放电动踏板车或电动自行车。',
    'rideSevenModuleTitle3': '行动区外',
    'rideSevenModuleText3': 'HRT 车辆属于操作区域内。如果您带着一辆离开，车辆将完全停止，您将无法结束旅程。',
    'rideEightModuleTitle': '停车——适用什么？',
    'rideEightModuleText1': '与不同的骑行区域一样，各个城市的停车规定也有所不同 - 请务必检查应用程序以了解您所在地区的适用情况。',
    'rideEightModuleText2': '正确的停车不仅仅关乎美观，还关乎美观。最重要的是，这是安全性和可及性的问题。',
    'rideEightModuleBtn': '了解有关停车的更多信息',
    'RideNineModuleTitle': '定价和通行证',
    'RideNineModuleText': '通过按月订阅、获得一日通行证或直接按需付费，以更少的费用获得更多的骑行体验。价格因城市而异 - 请检查 HRT 应用程序，了解您所在地区适用的确切价格。',
    'RideNineModuleBtn': '查看价格',
    'RideTenModuleTitle': '还有疑问吗？',
    'RideTenModuleText': '在我们的帮助中心找到最常见问题的答案，您还可以在其中找到有关我们服务的大量提示、技巧和有用信息。',
    'RideTripModuleText1': '安全骑行',
    'RideTripModuleText2': '停车',
    'RideTripModuleText3': '车辆',
    'RideTenModuleBtn': '访问帮助中心',
    'moreFirstModuleTitle': '停车变得简单',
    'moreFirstModuleText': '这就是您一直在寻找的指南。',
    'moreSecondModuleText': '在 HRT，我们相信安全和包容的城市是为生活而生的。因此，我们敦促您始终正确停车，并帮助保持人行道成为所有人都可以使用的空间。',
    'moreThreeModuleTitle1': '首先，基础知识：',
    'moreThreeModuleTitle2': '了解当地规则',
    'moreThreeModuleText': '请随时了解当地有关停车的规则和规定并遵守它们。请继续阅读以了解更多信息。',
    'moreFourModuleTitle': '表现出体贴',
    'moreFourModuleText': '确保不要阻塞任何出入点或人行横道，或以任何其他方式阻碍行人或其他车辆的路径。',
    'moreFiveModuleTitle': '不同城市-不同规定',
    'moreFiveModuleText': '通常适用以下其中一项：',
    'moreSixModuleTitle': '强制停车区',
    'moreSixModuleText': '在一些城市，只允许在停车架和其他指定用于电动滑板车和电动自行车停车的空间内停车。如果您位于这样的城市，这些强制性停车位在应用程序中会以蓝色标记（“停车区”）。出发前，使用应用内地图找到目的地附近的停车区。',
    'moreSevenModuleTitle': '自由浮动停车场',
    'moreSevenModuleText': '在其他城市，除了应用内地图上标记的所谓禁止停车和禁止骑行区域外，大部分运营区域都允许停车。在这里，您需要将车辆停放在平行且靠近墙壁的地方、车架上或指定的停车位内。',
    'moreEightModuleTitle': '综合停车规则',
    'moreEightModuleText1': '还有一些城市还适用多种法规。这通常意味着市中心有强制停车区，而根据自由浮动模式，允许在市中心外停车。',
    'moreEightModuleText2': '查看该应用程序，了解有关您所在地区适用的具体停车规则和规定的更多信息。',
    'MoreNineModuleTitle': '正确停车的重要性',
    'MoreNineModuleText1': '正确的停车不仅仅关乎美;最重要的是，',
    'MoreNineModuleText2': '这是安全性和可及性的问题。',
    'MoreNineModuleText3': '详细了解我们如何通过一流的技术来改善城市的安全停车',
    'MoreNineModuleText4': '解决方案',
    'MoreNineModuleText5': '不遵守当地停车规则违反了我们的规定',
    'MoreNineModuleText6': '用户协议',
    'MoreNineModuleText7': '并可能导致罚款。',
    'MoreTenModuleTitle': '看到放错地方的车辆吗？',
    'MoreTenModuleText1': '如果您看到我们的一辆电动滑板车或电动自行车停在不适当的位置，请',
    'MoreTenModuleText2': '向我们的运营团队报告,',
    'MoreTenModuleText3': '以便他们能够迅速采取行动。',
    'MoreTripModuleText1': '安全骑行 ',
    'MoreTripModuleText2': '怎么运行的',
    'MoreTripModuleText3': '可持续发展',
    'PriceFirstModuleTitle': '定价和通行证',
    'PriceFirstModuleText': '简单的定价，无限的可能性。选择最适合您的！',
    'PriceSecondModuleTitle': '选择您的价格计划',
    'PriceThreeModuleTitle': '适合偶尔骑行',
    'PriceThreeModuleText1': '按量付费的定价选项非常适合偶尔短途旅行的乘客。',
    'PriceThreeModuleText2': '您需要支付大约 1 美金来解锁车辆，然后每分钟支付 0.14 到 0.20 美金。',
    'PriceThreeModuleText3': '价格示例：平均而言，在美国使用即用即付方式乘坐 10 分钟的费用约为 2.5 美金（在应用任何折扣之前）。',
    'PriceThreeModuleBtn': '尝试按量付费',
    'PriceFourModuleTitle': '两个轮子上的一天',
    'PriceFourModuleText1': '有了 HRT 日通票，您在开始骑行时无需支付通常的解锁费用，并且在 24 小时内可以使用大约 100-120 分钟的骑行时间。',
    'PriceFourModuleText2': '在美国，一日通行证的价格通常为 7 至 10 美金。它非常适合探索新的或已知的城市。',
    'PriceFourModuleText3': '价格示例：如果您使用一日通票乘坐大约 5 次游乐设施，则每次乘坐的平均费用约为 1.5 美金。',
    'PriceFourModuleBtn': '获取一日通行证',
    'PriceFiveModuleTitle': '对于经常骑行的人来说',
    'PriceFiveModuleText1': '通过每月 HRT Pass 订阅，走向绿色并省钱。有了这张通行证，您无需支付任何解锁费用，并且每月可以选择骑行分钟数。',
    'PriceFiveModuleText2': '每月订阅选项和费用因地点而异。例如，在美国，包含 300 分钟的订阅费用通常为每月 35 美金左右。这样您就可以进行大约 30 次每次 10 分钟的骑行，或者 20 次每次 15 分钟的骑行。',
    'PriceFiveModuleText3': '价格示例：平均而言，在美国使用月票的每次乘车费用为 0.7 美金至 1 美金',
    'PriceFiveModuleBtn': '获得月票',
    'PriceSixModuleTitle': '使用 HRT Pass 始终免费解锁',
    'PriceSixModuleText': '如果您用完了日票或月票中包含的所有分钟数，请不要担心！您仍然可以免费解锁所有游乐设施，并且只需支付标准分钟费用，直到您的通行证过期或自动续订。转到该应用程序以查看您所在位置的确切定价并阅读完整条款。',
    'PriceSixModuleBtn': '打开HRT应用程序 ',
    'PriceSevenModuleTitle': '付款方式',
    'PriceSevenModuleText1': '我们接受多种流行的付款方式以及一些当地人喜爱的付款方式。',
    'PriceSevenModuleText2': '下载',
    'PriceSevenModuleText3': '该应用程序',
    'PriceSevenModuleText4': '跳上车去。',
    'PriceEightModuleTitle': '安全骑行并解锁独家优惠',
    'PriceEightModuleText': '完成我们的在线交通学校、将车停在指定的奖励区域或拍摄佩戴头盔的自拍照即可获得奖励。',
    'PriceEightModuleBtn': '学习安全骑行',
    'PriceNineModuleText1': '本文提及的价格、产品、功能和促销活动可能会发生变化，并且可能会根据地点、时间、车辆类型和其他情况等因素而有所不同。所提供的信息仅供一般参考之用，并不全面代表您所在城市、地区或国家可用和适用的具体定价、促销、付款方式或产品。',
    'PriceNineModuleText2': '本页提到的价格为近似价格，可能需要根据当地法规和要求收取额外费用和其他费用。您有责任在使用该服务之前熟悉任何此类额外费用。',
    'PriceNineModuleText3': '为确保您获得最准确和最新的定价信息，请参阅 HRT 应用程序了解您所在地区的具体价格和促销活动。',
    'PriceTripModuleTitle1': '怎么运行的',
    'PriceTripModuleTitle2': '安全骑行',
    'PriceTripModuleTitle3': '停车处',
    'HelpHeaderTitle': '我们能帮你什么吗？',
    'HelpHeaderText': '搜索帮助文章',
    'HelpCategoryText1': '入门',
    'HelpCategoryText2': '账户和付款',
    'HelpCategoryText3': '为您的乘车提供帮助',
    'HelpCategoryText4': '停车指南',
    'HelpCategoryText5': '安全和条款与条件',
    'HelpCategoryText6': '长期租赁（英国）',
    'BlogHeaderTitle': 'HRT 博客',
    'BlogHeaderText': '来自微移动运动的新闻、更新和见解。',
    'BlogNewsTitle1': 'HRT 与欧洲移动领导者联手组建未来移动工作组',
    'BlogNewsOneText1': '作为欧洲出行领域的关键参与者，HRT 一直致力于推动可持续交通解决方案，并为共享出行的发展做出贡献。秉持协作和创新精神，我们很高兴宣布加入欧盟未来移动工作组。',
    'BlogNewsOneText2': '该工作组于 2024 年 1 月与欧盟交通委员 Adina Vălean 合作成立，汇集了共享出行、电动汽车充电、磁悬浮、铁路、客车、货运和空中交通领域 18 家有影响力的公司和初创公司。我们的共同目标是为政策制定者提供宝贵的见解，在现有交通网络中培育可持续、负担得起的互联移动解决方案。',
    'BlogNewsOneText3': 'HRT 与 Bolt、Cabify、Flix、Volocopter 等备受尊敬的合作伙伴一起，致力于塑造交通运输的未来。通过制定一套全面的建议，我们力求为释放出行行业固有的经济、社会和环境效益做出贡献。这些建议将于春季提交给瓦林专员。',
    'BlogNewsOneText4': 'HRT 首席运营官兼工作组创始成员 Douglas Stark 表示：“我们对潜在影响感到兴奋，并期待与其他行业领导者在这一变革之旅中进行合作。”',
    'BlogNewsTitle2': '重新招标英国电动滑板车试验：HRT 对如何正确实施的反思',
    'BlogNewsTwoText1': '英国电动滑板车试验现已运行三年多。2023 年 11 月，政府没有宣布长期立法将微型交通牢固地嵌入我们的交通系统，而是再次将其延长至 2026 年 5 月。一些管理当地电动滑板车试验的议会和地区当局正在审查现有合同，决定是否延长与现有供应商的条款或为新合同进行招标。\n',
    'BlogNewsTwoText2': '一名记者最近报道了英格兰西部一家新的微型移动运营商的过渡指出，“如果它没有坏，为什么要修理它”？许多社交媒体帖子都标记了我们，要求我们返回布里斯托尔，直到 10 月份运营商发生变化之前，我们在那里建造并运营了欧洲最成功的电动滑板车计划之一。在社交媒体和消息据报道，我们的前骑手抱怨街道上缺乏随上随下踏板车，没有长期租赁踏板车，缺乏停车位，还询问了有关承诺的电动自行车和货运自行车的问题但到了 12 月初，仍未能落实新合同。',
    'BlogNewsTwoText3': '这促使我们反思地方当局和微型交通运营商应如何合作，以确保运营商的任何变更尽可能顺利，最重要的是，尽量减少对乘客服务的干扰。试验的成功意味着人们依靠它们去工作、接受教育、进行商务约会、去商店和休闲。如果服务中断——要么几个月没有滑板车，要么滑板车数量大大减少——那么这些骑手就被迫寻找另一个交通来源。可悲的是，我们在社交媒体上看到一些报道称一些人选择再次开始使用汽车。',
    'BlogNewsTwoText4': '借鉴我们今年在英国参与的转型经验，包括在伦敦、切尔滕纳姆和格洛斯特开始运营。这些是我们对最佳实践的想法。',
    'BlogNewsTwoText5': '良好的合同设计至关重要',
    'BlogNewsTwoText6': '议会不应将电动滑板车视为短期摇钱树，并记住我们是唯一不接受公共补贴或资本投资的可持续交通形式。我们投资仓库、人员和硬件；这是一个资本密集型行业，利润率低。为了在财务上可持续，我们需要合同在财务上可持续；这意味着应避免盲目拍卖收入份额。我们不能亏本运营计划，而要求的收入分成会使服务面临风险。这种方法可能会导致运营商倒闭、议会不得不重新采购以及公民失去他们所依赖的交通选择。',
    'BlogNewsTwoText7': '必须验证运营商是否能够兑现承诺',
    'BlogNewsTwoText8': '我们总是很高兴地方当局参观我们的一个仓库，了解它们的运营情况，检查我们健全的健康和安全状况，观看我们的机械师工作，以及试用我们的电动滑板车和电动自行车。我们可以演示并解释如何才能运营财务上可持续且负责任的服务。这样他们就会看到我们能够兑现我们的承诺并确保不偷工减料。',
    'BlogNewsTwoText9': '协作是关键',
    'BlogNewsTwoText10': '确保合同适用于每个人——乘客、议会、运营商和广大民众，符合每个人的利益。实现这一目标的最佳方法是运营商与对社区了如指掌的地方当局密切合作，并听取与我们的车辆共享道路和路径的乘客和其他道路使用者或行人的反馈。',
    'BlogNewsTwoText11': '*Matthew Pencharz 是 HRT 英国、爱尔兰和法国的公共政策主管。他很高兴与当地政府进行进一步对话，寻求对该地区的微型交通服务进行招标。',
    'BlogNewsReadMore': '阅读更多',
    'BlogNewsReadShare': '分享',
    'VisionFirstTitle': '目前世界上一半以上的人口居住在城市',
    'VisionFirstText1': '到2050年，这一比例将达到三分之二。随着越来越多的人从农村地区迁移到城市，道路上的汽车将会越来越多，家庭和工作场所附近的交通拥堵热点将会越来越多，绿地也会越来越少。',
    'VisionFirstText2': '除非我们有足够的勇气挑战现状并采取行动。',
    'VisionSecondText1': '我们的愿景：',
    'VisionSecondText2': '为生活而生的城市，没有噪音和污染。',
    'VisionThreeText1': '在 HRT，我们相信未来城市道路将被人们重新占领。我们希望人们重新回到城市转型的中心，目标是创造一个人们在安全、健康、污染更少、噪音更少、空间更大的环境中生活和行动的明天。',
    'VisionThreeText2': '我们看到的未来是一个可持续的未来，循环是常态，共享出行是发展之道。个人、企业和政府都承担起责任，努力减少对气候的影响，帮助保护我们的地球，并为所有人建设更宜居的城市做出贡献。',
    'VisionThreeBtn': '愿景声明',
    'VisionFourText': '展望您城市的未来',
    'VisionFiveTitle': '加拿大-蒙特利尔',
    'VisionFiveText1': '蒙特利尔市中心是一条繁忙的主干道，有多达五车道的交通。 我们与 Jaja Architects 的提案旨在蒙特利尔创造一条绿色脊柱，而不是一条被污染的道路。',
    'VisionFiveText2': '绿色脊柱将优先考虑步行、骑自行车、微型交通、公共交通和货运自行车。 此外，它将为人们提供一个享受不那么疯狂、更有活力的市中心的空间。',
    'VisionSixTitle': '英国-伦敦-大本钟',
    'VisionSixText1': '伦敦市中心和郊区之间的交通走廊主要以汽车为主，阻碍了微型交通的推广。',
    'VisionSixText2': '为了解决这一问题，伦敦城市街道提案建议减少单向车道并创建一个交通枢纽来连接微型交通和公交车。 还包括一条自行车道，通过拆除 50% 的现有停车位创建的“灵活区域”与交通分开。',
    'VisionSix1Title': '洛杉矶-黄金海岸',
    'VisionSix1Text1': '黄金海岸的大部分交通和娱乐活动都是步行完成的。为了开发这一地区，该公司咨询了相关政府官员，并获得了政府批准在这些街道上使用共享平衡自行车。我们的产品给游客带来了更多的便利。共享平衡自行车不受步行街的限制。该产品在最大程度上帮助了每个人。',
    'VisionSevenTitle': '15分钟城市',
    'VisionSevenText1': '城市发展的新范式强调通过步行、骑自行车或公共交通在 15 分钟半径内获得日常必需品。HRT 支持这一城市发展理念，并相信共享微交通在加强公共交通网络方面发挥着重要作用。',
    'VisionEightTitle': '我们的使命：',
    'VisionEightText1': '为每个人提供安全、可持续和可靠的微型交通。',
    'VisionNineTitle': '为了实现我们的愿景，我们提供安全、价格实惠且易于使用的不同模式的轻型移动车辆，让人们能够按照自己的意愿在城市中出行。我们相信，通过更广泛地提供可持续交通选择，我们可以改善世界各地城市的生活质量，同时也为应对气候变化做出贡献。',
    'VisionNineText1': '例如，将公共汽车和踏板车结合起来可以减少障碍和总旅行时间，使公共交通成为汽车的可行替代方案。这在通勤的背景下尤其重要，无论是工作、会见亲戚还是与朋友参观画廊。',
    'VisionTenTitle': '微型交通和公共交通之间更紧密的联系有利于城市',
    'VisionTenText1': 'HRT、Mobimeo 和德国铁路运营商 S-Bahn Stuttgart 之间的一项联合计划已被证明可以改善电动滑板车停车并促进公共交通票务销售。该项目是微型交通和公共交通提供商之间互利整合方向上迈出的有希望的一步。',
    'VisionTenBtn': '阅读案例研究',
    'VisionTextTitle': '与其他运输服务整合',
    'VisionTextText1': 'HRT 提供了一种有效的方法来扩展现有公共交通系统的覆盖范围和生产力，否则需要大量基础设施投资。',
    'VisionTextText2': '我们的即用型框架使用 GBFS 和 MDS 等共享移动运营商的标准应用程序编程接口 (API)，使我们的服务能够轻松地与公共交通、路线规划平台和移动即服务 (MaaS) 应用程序集成。',
    'VisionTextBtn': '阅读更多',
    'VisionSchemeTitle': '我们的旅程才刚刚开始',
    'VisionSchemeText1': '关注我们的博客，了解 HRT Technology 的最新新闻和见解。',
    'VisionSchemeBtn': '阅读我们的博客',
    'AboutOneTitle': '重构想你的城市',
    'AboutOneText': '城市为生活而生，没有噪音和污染',
    'AboutTwoTitle': '关于我们',
    'AboutTwoText1': 'HRT 是全球发展最快的移动出行公司之一。',
    'AboutTwoText2': '我们诞生于美国，通过最先进的硬件和软件解决方案推动创新，塑造可持续交通和城市发展的未来。',
    'AboutTwoText3': '体验使用我们的共享电动汽车从\n' +
        '            A 点到 B 点的便利，同时减少空气和噪音污染并缓解整个欧洲的交通拥堵。',
    'AboutThreeTitle': '对于骑手',
    'AboutThreeText1': 'HRT 通过我们的 HRT 应用程序按需提供一流的共享电动汽车，让城市出行变得更加便捷。',
    'AboutThreeText2': '只需使用应用内地图找到附近的电动滑板车或电动自行车，用手机扫描车把上的二维码即可解锁车辆，然后开始轻松自由地探索城市。',
    'AboutMoreBtn': '了解更多',
    'AboutFourTitle': '对于城镇和城市',
    'AboutFourText1': 'HRT 与城市官员和组织携手合作，解决城市痛点，提供定制解决方案，满足您所在地区的特定交通需求和机会。',
    'AboutFourText2': '我们的方法使 HRT\n' +
        '      成为城市值得信赖和可靠的合作伙伴，我们作为欧洲许可和招标项目中拥有最多踏板车的运营商的地位证明了这一点。',
    'AboutFiveTitle': '用于运输服务',
    'AboutFiveText1': 'HRT 提供了一种有效的方法来扩展现有公共交通系统的覆盖范围和生产力，否则需要大量基础设施投资。',
    'AboutFiveText2': '我们的即用型框架使用适用于共享出行运营商的标准应用程序编程接口 (API)，使我们的服务能够轻松地与公共交通、路线规划平台和出行即服务 (MaaS) 应用程序集成。',
    'AboutSixTitle': '我们的愿景：打造无噪音、无污染的宜居城市',
    'AboutSixText1': '在 HRT，我们相信未来城市道路将被人们重新占领。',
    'AboutSixText2': '我们将人重新置于城市转型的中心，旨在创造一个人们在安全、健康、污染更少、噪音更少、空间更大的环境中生活和行动的明天。',
    'AboutSixText3': '我们相信，通过更广泛地提供可持续交通选择，我们可以改善世界各地城市的生活质量，同时也为应对气候变化做出贡献。',
    'AboutSixBtn': '我们的愿景声明',
    'AboutEightTitle': '我们的使命：为每个人提供安全、可持续和可靠的微型交通',
    'AboutEightText1': '为了实现我们的愿景，我们提供安全、价格实惠且易于使用的不同模式的轻型移动车辆，让人们能够按照自己的意愿在城市中出行。',
    'AboutEightText2': '通过减少对汽车的依赖并改善可持续交通的获取，我们可以帮助为每个人创建更好、更安全的城市。',
    'AboutEightBtn': '怎么运行的',
    'AboutNineTitle': '微型交通有助于减少城市地区的碳排放',
    'AboutNineText1': '通过提供替代交通方式和增加公共交通的便利性，微型交通可以帮助城市实现其气候目标。我们采取全面的方法来最大程度地减少对环境的影响，并不断改进我们的可持续实践。',
    'AboutTenTitle': '微型交通有助于减少城市地区的碳排放',
    'AboutTenText1': '通过提供替代交通方式和增加公共交通的便利性，微型交通可以帮助城市实现其气候目标。我们采取全面的方法来最大程度地减少对环境的影响，并不断改进我们的可持续实践。',
    'About11Title': '我们的团队是我们最大的力量',
    'About11Text1': '在 HRT，我们的团队与我们所服务的社区一样多元化，正在引领更可持续的交通方式。',
    'About11Text2': '无论您是在街道上维护我们的车辆、拓展新市场，还是开发我们的应用程序，您都将成为塑造未来城市的充满活力和热情的团队的一员。',
    'About11Text3': '查看我们当前的职位空缺并立即申请。',
    'About11Btn': '查看职位',
    'About12Title': 'HRT 可在各国 100 多个城镇使用',
    'About12Country1': '奥地利',
    'About12Country1Town1': '维也纳',
    'About12Country2': '比利时',
    'About12Country2Town1': '布鲁塞尔',
    'About12Country3': '丹麦',
    'About12Country3Town1': '奥尔堡',
    'About12Country3Town2': '奥尔胡斯',
    'About12Country3Town3': '欧登塞',
    'About12Country4': '芬兰',
    'About12Country4Town1': '赫尔辛基',
    'About12Country4Town2': '于韦斯屈莱',
    'About12Country4Town3': '坦佩雷',
    'About12Country4Town4': '图尔库',
    'About12Country5': '法国',
    'About12Country5Town1': '马赛',
    'About12Country6': '德国',
    'About12Country6Town1': '亚琛',
    'About12Country6Town2': '奥格斯堡',
    'About12Country6Town3': '柏林',
    'About12Country6Town4': '波鸿',
    'About12Country6Town5': '波恩',
    'About12Country6Town6': '科隆',
    'About12Country6Town7': '多特蒙德',
    'About12Country6Town8': '杜塞尔多夫',
    'About12Country6Town9': '埃森',
    'About12Country6Town10': '法兰克福',
    'About12Country6Town11': '汉堡',
    'About12Country6Town12': '汉诺威',
    'About12Country6Town13': '卡尔斯鲁厄',
    'About12Country6Town14': '莱比锡',
    'About12Country6Town15': '吕贝克',
    'About12Country6Town16': '美因茨/威斯巴登',
    'About12Country6Town17': '明斯特',
    'About12Country6Town18': '慕尼黑',
    'About12Country6Town19': '纽伦堡',
    'About12Country6Town20': '普福尔茨海姆',
    'About12Country6Town21': '罗斯托克',
    'About12Country6Town22': '斯图加特',
    'About12Country7': '意大利',
    'About12Country7Town1': '米兰',
    'About12Country7Town2': '摩德纳',
    'About12Country7Town3': '雷焦艾米利亚',
    'About12Country7Town4': '罗氏',
    'About12Country7Town5': '都灵',
    'About12Country8': '美国',
    'About12Country8Town1': '洛杉矶',
    'About12Country8Town2': '旧金山',
    'About12Country8Town3': '拉斯维加斯',
    'About12Country8Town4': '奥克兰',
    'About12Country8Town5': '温哥华',
    'About12Country8Town6': '西雅图',
    'About12Country8Town7': '波特兰',
    'About12Country8Town8': '丹佛',
    'About12Country8Town9': '檀香山',
    'About12Country8Town10': '弗雷斯诺',
    'About12Country8Town11': '纽约',
    'About12Country8Town12': '华盛顿',
    'About12Country9': '西班牙',
    'About12Country9Town1': '马拉加',
    'About12Country9Town2': '塞维利亚',
    'About12Country10': '瑞典',
    'About12Country10Town1': '布罗斯',
    'About12Country10Town2': '埃斯基尔斯图纳',
    'About12Country10Town3': '哥德堡',
    'About12Country10Town4': '哈尔姆斯塔德',
    'About12Country10Town5': '赫尔辛堡',
    'About12Country10Town6': '林雪平',
    'About12Country10Town7': '隆德',
    'About12Country10Town8': '马尔默',
    'About12Country10Town9': '诺尔雪平',
    'About12Country10Town10': '厄勒布鲁',
    'About12Country10Town11': '斯德哥尔摩',
    'About12Country10Town12': '乌普萨拉',
    'About12Country10Town13': '韦斯特拉斯',
    'About12Country11': '瑞士',
    'About12Country11Town1': '巴塞尔',
    'About12Country11Town2': '伯尔尼',
    'About12Country11Town3': '弗劳恩费尔德',
    'About12Country11Town4': '伊尔瑙-埃夫雷蒂孔',
    'About12Country11Town5': '温特图尔',
    'About12Country11Town6': '苏黎世',
    'About12Country12': '加拿大',
    'About12Country12Town1': '温哥华',
    'About12Country12Town2': '卡尔加里',
    'About12Country12Town3': '艾德蒙顿',
    'About12Country12Town4': '哈利法克斯',
    // 'About12Country12Town5': '凯特琳',
    // 'About12Country12Town6': '利物浦',
    // 'About12Country12Town7': '伦敦',
    // 'About12Country12Town8': '北安普顿',
    // 'About12Country12Town9': '牛津',
    // 'About12Country12Town10': '朴茨茅斯',
    // 'About12Country12Town11': '拉什登和海厄姆·费勒斯',
    // 'About12Country12Town12': '南安普顿',
    // 'About12Country12Town13': '威灵伯勒',
    'About13Title': '领导',
    'About13Text1': '了解 HRT Technology领导团队的一些成员，他们负责为我们公司的方向制定路线，以及一些在美国各地将其付诸实践的地区领导人。',
    'About14Title': '到目前为止我们的旅程',
    'About14Text1': '2018年',
    'About14Text2': 'HRT 由 Fredrik Hjelm、Adam Jafer、Filip Lindvall 和 Douglas Stark 在斯德哥尔摩创立，是欧洲先锋电动滑板车运营商。从第一天起，我们的瑞典传统就推动了我们对交通安全、出色的停车解决方案、运营效率以及为所有人提供负担得起的、便捷的交通方式的承诺。',
    'About14Text3': '2019年',
    'About14Text4': 'HRT 在 40 多个欧洲城镇推出了电动滑板车，所有市场的骑行次数均达到 100 万次。为了提高骑手安全，我们创建了 RideSafe Academy（以前称为 RideLikeVoila）——第一家针对电动滑板车的在线交通学校。为了进一步提高我们的卓越运营，我们引入了可更换电池，减少了能源消耗和排放。',
    'About14Text5': '2020年',
    'About14Text6': '2020 年，HRT 在多个欧洲城市赢得了多项开创性的电动滑板车招标，包括在参与英国电动滑板车试验计划的大多数城镇获得独家许可 - 巩固了我们作为值得信赖和可靠合作伙伴的地位对于城市。',
    'About14Text7': '我们还推出了多项应用内安全功能，例如低速模式、夜间骑行反应测试，以及 HRT Pass——一种基于订阅的定价模式，使使用 HRT 作为日常交通方式更加经济实惠。',
    'About14Text8': '2021年',
    'About14Text9': 'HRT 通过推出首款电动自行车扩大了其移动产品范围。为了继续创新，我们推出了新一代电动滑板车 Voiager 4，其具有转向指示器以提高安全性，并改进了地理定位以提供更精确的停车解决方案。',
    'About14Text10': '在幕后，我们通过实时需求预测和机器学习支持的车队优化软件不断改进我们的尖端运营技术。这确保了我们的车辆得到最大程度的利用，并为乘客提供最佳的可用性，这是我们卓越运营背后的关键因素。',
    'About14Text11': '2022年',
    'About14Text12': '2022 年，HRT 实现了 1 亿次乘坐的重要里程碑，并推出了第 100 个城市。我们还推出了下一代车辆 Voiager 5，这是我们迄今为止最安全、最耐用的电动滑板车。Voiager 5 采用高度模块化的设计，可简化维护并促进零件的重复使用，经久耐用，车辆使用寿命长达五年。',
    'About14Text13': '我们继续在欧洲推广“HRT Parking Assistant”产品功能，支持用户正确停车并奖励良好停车，同时对反复出现的不良停车行为采取惩罚措施。此外，HRT 能够赢得重新招标，例如在法国和丹麦，这展示了重新招标城市对 HRT 的信任。',
    'About15Title': '我们的旅程才刚刚开始',
    'About15Text1': '关注我们的博客，了解 HRT Technology 的最新新闻和见解。',
    'About15Btn': '阅读我们的博客',
    'CitiesOneTitle1': '您的城市，我们的',
    'CitiesOneTitle2': '承诺',
    'CitiesOneText1': 'HRT 与城市官员和组织携手解决城市痛点，提供定制解决方案来解决您所在地区的特定交通需求和挑战。',
    'CitiesTwoTitle': '为每个人提供安全、可持续和可靠的微型交通',
    'CitiesTwoText1': '我们提供不同模式的轻型机动车辆，这些车辆安全、价格实惠且易于使用，让人们能够按照自己的意愿在城市中出行。',
    'CitiesTwoText2': '通过减少对汽车的依赖并改善可持续出行的机会，我们可以加强公共交通网络，帮助为每个人创造更好、更安全、更宜居的城市。',
    'CitiesThreeTitle': '解决安全和停车挑战',
    'CitiesThreeText1': '在 HRT，受美国传统和“零愿景”方法的启发，我们致力于安全。这意味着优先考虑所有道路使用者的安全，而不仅仅是我们的骑手。我们利用先进技术并提供广泛的功能来提高安全性。',
    'CitiesThreeText2': '例如，我们的技术使我们能够防止人行道和醉酒骑行，并限制指定区域的车辆速度。更重要的是，我们行业领先的停车解决方案解决了行人和弱势道路使用者的关键安全问题之一：道路受阻。',
    'CitiesFourTitle': '没有一个城镇或城市是完全一样的',
    'CitiesFourText1': '我们知道每个城市都有其独特的挑战和机遇，我们致力于提供定制的安全解决方案来应对这些挑战和机遇。拥有欧洲最大的获得许可和招标的电动滑板车车队，我们知道如何解决安全和停车问题。',
    'CitiesFourText2': '例如，在某些地区，我们与市政府合作安装实体停车架和油漆停车位，有效解决停车难题。在其他地区，我们使用地理围栏技术来防止在某些位置停车。我们还提供“停车助手”功能，奖励正确停车的用户，并对反复出现的不良停车行为采取惩罚措施。',
    'CitiesFourText3': '我们的目标是与每个城市合作，创建量身定制的解决方案，解决其所在地区的特定安全挑战。我们相信，城市优先的方法是为每个人创造安全和可持续交通选择的关键。',
    'CitiesFourBtn': '我们的方法',
    'CitiesFiveTitle': '解决第一公里和最后一公里问题',
    'CitiesFiveText1': 'HRT 提供了一种有效的方法来解决第一英里和最后一英里的问题，并改善城市公共交通的便利性。例如，现在 55% 的骑手通常将电动滑板车旅行与公共交通结合起来（根据我们的调查）。',
    'CitiesFiveText2': '我们的即用型框架使用共享出行运营商的标准应用程序编程接口 (API)，例如 GBFS 和 MDS，使我们的服务轻松与公共交通集成。',
    'CitiesSixTitle': '利用数据改善交通路线',
    'CitiesSixText1': '通过从我们的服务中收集复杂的数据并以易于理解的方式呈现，我们可以帮助城市官员全面了解共享出行。',
    'CitiesSixText2': '例如，我们可以提供热图，用于分析城市最繁忙的区域，然后用于优化公交路线并识别需要额外覆盖的位置。',
    'CitiesSevenTitle': '成功所需的资源',
    'CitiesSevenText1': '获取数据对于城市在共享微交通方面取得成功至关重要。我们使用既定的数据共享实践和定制仪表板，按需提供实时信息、使用模式、法规合规性等。',
    'CitiesSevenText2': '这种透明度和我们敬业的本地团队努力维护和管理我们的机队和地面运营，确保了我们服务的高质量和可控性。',
    'CitiesEightTitle': '通过提供替代交通方式并增强公共交通的便利性，微型交通可以帮助城市实现其气候目标。',
    'SafeOneTitle': '我们对安全的承诺',
    'SafeOneText': 'HRT 采用数据驱动、研究主导的方法来构建街道上最安全的微型出行服务，我们的安全措施发挥着重要作用。',
    'SafeTwoTitle': '促进安全透明度',
    'SafeTwoText1': '我们的安全报告具有双重目的：不仅促进问责制，还使我们能够利用我们的见解来减少事故并为所有人创造更安全的公共空间。',
    'SafeTwoText2': '通过提高我们安全举措的透明度并强调我们为实现零愿景目标而采取的步骤，我们希望激发人们对我们改善安全努力的信心。',
    'SafeTwoBtn': '下载报告',
    'SafeThreeTitle': 'HRT 的“零愿景”方法重点关注三个关键领域',
    'SafeThreeSTitle1': '教育',
    'SafeThreeText1': '促进安全骑车行为并使用先进技术保护行人',
    'SafeThreeSTitle2': '硬件',
    'SafeThreeText2': '制造最安全的车辆并采取积极措施防止事故发生。',
    'SafeThreeSTitle3': '合作',
    'SafeThreeText3': '与城市合作改善基础设施并创建更安全的街道。',
    'SafeFourTitle': '促进安全的骑车行为',
    'SafeFourText1': '为我们的用户提供安全骑行的知识和技能至关重要。',
    'SafeFourText2': '为了实现这一目标，我们通过在线学习平台 RideSafe Academy、数字教育和现场安全活动等多种方式持续提供交通规则教育和安全乘车培训。',
    'SafeFiveTitle': '保护行人',
    'SafeFiveText1': '我们利用先进技术确保行人和其他弱势道路使用者的安全，提供一系列提高安全性的功能。',
    'SafeFiveText2': '例如，我们的技术使我们能够防止人行道和醉酒骑行，并限制指定区域的车辆速度。此外，我们行业领先的停车解决方案解决了行人和弱势道路使用者的主要安全问题之一：道路受阻。',
    'SafeSixTitle': '没有一个城镇或城市是完全一样的',
    'SafeSixText1': '在 HRT，我们知道每个城市都有其独特的挑战和机遇，我们致力于提供定制的安全解决方案来应对这些挑战和机遇。拥有欧洲最大的获得许可和招标的电动滑板车车队，我们知道如何解决安全和停车问题。',
    'SafeSixText2': '例如，在某些地区，我们与市政府合作安装实体停车架和油漆停车位，有效解决停车难题。在其他地区，我们使用地理围栏技术来防止在某些位置停车。我们还提供“停车助手”功能，奖励正确停车的用户，并对反复出现的不良停车行为采取惩罚措施。',
    'SafeSixText3': '我们的目标是与每个城市合作，创建量身定制的解决方案，解决其所在地区的特定安全挑战。我们相信，这种方法是为每个人创造安全和可持续交通选择的关键。',
    'SafeSevenTitle': '设计更安全的乘车体验',
    'SafeSevenText1': '每一代新一代车辆都配备了各种安全改进，以应对共享微移动行业的独特挑战和需求。',
    'SafeSevenText2': '例如，作为对骑手和城市偏好的直接响应，我们实施了多项安全功能，例如双手刹车、转向灯和更大的车轮尺寸。',
    'SafeSevenBtn': '我们的车辆',
    'SafeEightTitle': '确保车辆时刻安全',
    'SafeEightText1': '我们的车辆配备了多个独特的传感器，持续监控车队的状况并采取主动措施防止事故发生。我们定期进行检查，包括现场质量检查和现场维修，以尽量减少到仓库的运输。',
    'SafeEightText2': '我们的传感器还能够检测我们的一辆车是否翻倒。这些信息以及我们报告工具“Report a HRT”的反馈会直接发送给我们当地的现场团队，使我们能够快速采取行动，确保街道上每个人的安全。',
    'SafeNineTitle': '指导城市完善基础设施',
    'SafeNineText1': '基础设施是确保微出行安全的最关键因素之一。利用我们车辆的数据和乘客的反馈，我们可以通过识别事故热点和路况恶化的区域，帮助城市根据公民的实时需求改进城市基础设施。',
    'ParkHeaderTitle': '停车解决方案',
    'ParkHeaderText': '微交通已成为缓解道路交通拥堵的行之有效的解决方案。然而，确保负责任的停车做法以防止其他区域出现拥堵至关重要。',
    'ParkOneTitle': '正确停车的重要性',
    'ParkOneText1': '作为一家负责任的微型交通提供商，我们认识到 HRT 车辆占用城市空间，我们有责任确保用户以负责任且适当的方式停放它们。',
    'ParkOneText2': '停车合规性是我们安全承诺的重要组成部分，在确保乘客和行人安全方面发挥着关键作用，特别是对于行动不便和视力不佳的弱势群体而言。',
    'ParkTwoTitle': '我们的解决方案已被证明有效',
    'ParkTwoText1': '我们解决停车难题的方法在业内是独一无二的，并且我们的解决方案已被证明是有效的。',
    'ParkTwoText2': '例如，挪威交通经济研究所针对共享电动滑板车停车解决方案进行的一项研究就证明了这一点。',
    'ParkTwoBtn': '阅读研究报告',
    'ParkThreeTitle': '没有一个城镇或城市是完全一样的',
    'ParkThreeText1': '我们知道每个城市都有其独特的挑战和机遇，因此，我们提供定制的解决方案来应对这些挑战和机遇。通过高端技术解决方案、高精度GPS、高效运营等针对性举措，保障停车有序、安全、合法。',
    'ParkFourTitle': '了解我们的一些尖端停车解决方案',
    'ParkFiveTitle': '专用停车位',
    'ParkFiveText1': '为共享电动滑板车分配专用停车位是一种非常有效的解决方案。通过在电动滑板车应用程序中建立既定的禁止停车区，并使用旨在防止骑手在安全停车位之外结束旅程的软件，可以鼓励骑手寻求此类解决方案。',
    'ParkFiveText2': '我们与城市合作，仔细规划运营区域内停车区的供应和位置。不同城市的最佳停车设计差异很大，具体取决于运营区域、城市密度和可用基础设施等因素。',
    'ParkFiveText3': '然而，某些方面仍然保持一致，包括：',
    'ParkFiveText4': '在部署强制停车位时（例如在人口稠密的城市中心），停车位应具有物理可识别性。',
    'ParkFiveText5': '强制停车位的密度至少应为每平方公里 30 个，以确保足够的可用性。',
    'ParkFiveText6': '每辆车可用停车位的比例应至少为3:1，以确保车流畅通。',
    'ParkFiveText7': '请阅读我们',
    'ParkFiveText8': '分享的行业建议',
    'ParkFiveText9': '来了解更多信息。',
    'ParkSixTitle': '科技造福所有人',
    'ParkSixText1': '我们的车辆配备了多个先进的传感器，当与最先进的计算机视觉和人工智能技术结合使用时，我们能够在车辆停放不当的情况下自动向 HRT 当地运营团队发出警报。',
    'ParkSixText2': '通过利用这项技术和数据，我们可以快速纠正任何停车问题并领先于潜在问题。',
    'ParkSevenTitle': '帮助用户停车',
    'ParkSevenText1': '激励停车通过为指定区域的停车提供折扣来鼓励更好的停车。此外，我们的“停车助手”可以帮助用户正确停车，并对良好停车进行奖励，同时对反复出现的不良停车行为采取惩罚措施。',
    'ParkSevenText2': '每次行程结束时，乘客必须提交停车照片，以强调正确停车的重要性。该功能还可以在不合规停车的情况下向乘客提供反馈，确保及时有效地解决任何问题。',
    'ParkEightTitle': '集体努力',
    'ParkEightText1': '尽管不良停车行为仅限于一小部分乘客，但单辆停放不当的车辆可能会妨碍通行。我们的首要任务是开发一流的停车解决方案以及其他安全工作。我们的经验表明，运营商和电动滑板车提供商之间的密切合作对于实现这一目标至关重要。',
    'MobilityHeaderTitle': '移动合作伙伴',
    'MobilityHeaderText1': '让我们共同努力，实现明天的移动出行。',
    'MobilityOneTitle': '我们的出行合作伙伴对于实现我们打造无噪音、无污染、宜居城市的愿景至关重要',
    'MobilityOneText1': '从第一天起，我们的瑞典传统就激励我们致力于为所有人提供负担得起、方便且可持续的交通。我们与欧洲各地的众多公共交通当局和出行服务提供商合作，使所有公民都能获得各种此类出行服务。',
    'MobilityOneText2': '我们的集成解决方案提供了将其结合在一起的技术，使公民能够选择交通方式或不同方式的组合来满足他们的交通需求，从而为每次旅行创建量身定制的解决方案。因此，HRT 及其合作伙伴增加了选择的灵活性，推动更环保的旅行方式，并改变社会的出行方式。',
    'MobilityTwoTitle': 'HRT 的合作伙伴解决方案',
    'MobilityTwoText1': '我们的即用型框架使用共享出行运营商的标准 API，使任何合作伙伴和平台都可以轻松地与我们的服务集成。',
    'MobilityTwoTitle1': '轻型集成',
    'MobilityTwoText2': '我们的轻型集成可以共享有关我们车辆的实时信息，例如位置、可用性、定价以及与速度、停车和禁区相关的规则的地理围栏区域。轻度集成将使用户能够找到附近可用的共享微移动选项。预订车辆、付款和客户服务将由 HRT 处理。',
    'MobilityTwoText3': '我根据合作伙伴的需求，使用 GBFS（通用自行车共享馈送规范）和 MDS（移动数据规范）实现轻型集成。',
    'MobilityTwoTitle2': '深度集成',
    'MobilityTwoText4': '我们的深度集成使合作伙伴能够通过其平台提供完整的端到端 HRT 用户体验。通过单一界面为旅途中使用的所有方式（例如公共汽车、火车和 HRT）提供预订和支付流程支持，涉及公共交通的多式联运的便利性得以提高。',
    'MobilityTwoText5': '为了深度集成，HRT 开发了标准 MaaS（微移动即服务）框架，可以轻松集成完整的 HRT 服务。',
    'MobilityTwoTitle3': '物理集成：移动中心',
    'MobilityTwoText6': '我们还与公共交通合作伙伴和城市合作，支持创建移动枢纽。移动枢纽让人们有机会把汽车留在家里，利用不同的交通方式组合享受无缝旅行，同时改善交通枢纽周围的秩序和便利性。',
    'MobilityTwoText7': '例如，HRT 可以提供专用停车位、物理停车基础设施和使用模式分析。',
    'MobilityThreeTitle': '案例研究：将 HRT 与斯图加特的公共交通集成',
    'MobilityThreeText1': '斯图加特的一项试点举措表明，将共享微型交通整合到当地公共交通系统中可以帮助增加公共交通的乘客量。HRT 以及当地铁路运营商斯图加特 S-Bahn 和 MaaS 平台提供商 Mobimeo 都参与了该项目。',
    'MobilityThreeText2': 'HRT 的电动滑板车服务可通过 Mobility Stuttgart 应用程序进行预订，通过交通规划器将共享出行与公共交通连接起来，让用户更轻松地计划行程。此外，用户在组合不同的交通方式时可以获得优惠券，并且在火车站放置了电动滑板车的停车架。',
    'MobilityThreeText3': '研究显示，试点期间共享电动滑板车的出行量增加了 250%，火车票销量增长了 35%。该倡议还发现，火车站的实体停车基础设施可以减少电动滑板车造成的混乱。',
    'MobilityThreeBtn': '阅读案例研究',
    'MobilityFourTitle': '有兴趣与 HRT 合作吗？',
    'MobilityFourText1': '您是否有兴趣了解有关 HRT 集成微移动解决方案的更多信息或想讨论一个想法？与我们联系以开始对话或访问我们的合作伙伴文档。',
    'MobilityFourBtn': '联系我们',
    'MobilityFiveTitle': '伙伴',
    'MobilitySixTitle': '让我们共同在城市中创建一个更可持续、更高效的交通系统。',
    'SustainabilityHeaderTitle': '可持续发展',
    'SustainabilityHeaderText': '我们对气候和环境的承诺是整个业务的基础。',
    'SustainabilityOneTitle': '为生活而生的城市，没有噪音和污染',
    'SustainabilityOneText1': '微型交通有潜力通过提供汽车替代品和改善公共交通的便利性来帮助城市交通脱碳。',
    'SustainabilityOneText2': '我们采取整体方法来解决我们的环境足迹，并努力不断改进我们的可持续发展实践。',
    'SustainabilityTwoTitle': '迄今为止的重要步骤',
    'SustainabilityTwoTitle1': '增强移动性',
    'SustainabilityTwoText1': '共享微型交通为公共交通网络提供了便利的补充，增强了公民获得可持续交通的机会。例如，根据我们的用户调查，55% 的骑手通常将电动滑板车旅行与公共交通结合起来。',
    'SustainabilityTwoTitle2': '新电池技术',
    'SustainabilityTwoText2': '可更换电池显着减少了排放、能源消耗和交通拥堵。电池直接在街上更换，只需将废旧电池运输充电。',
    'SustainabilityTwoTitle3': '循环车辆方案',
    'SustainabilityTwoText3': '通过我们的循环车辆计划，我们成功地将车队的使用寿命从 2018 年的不到一年延长到 2022 年 V5 车型的平均使用寿命（经第三方验证）。',
    'SustainabilityThreeTitle': '我们不断努力减少排放，目标是到 2030 年实现气候积极。',
    'SustainabilityFourTitle': '测量排放量',
    'SustainabilityFourText': '作为一家致力于问责制和透明度的公司，我们是第一家发布全面的服务生命周期评估的电动滑板车运营商。我们的生命周期评估使我们能够识别导致排放的材料、生产和报废流程，并相应地集中我们的减排工作。',
    'SustainabilityFiveTitle': '减少排放',
    'SustainabilityFiveText': '我们的环境行动计划以减排举措为目标，重点是延长车辆的使用寿命、向零排放运营过渡以及实施循环实践。',
    'SustainabilitySixTitle': '我们正在努力到 2025 年使我们的服务完全循环，实现零浪费。',
    'SustainabilitySevenTitle': '重新思考',
    'SustainabilitySevenText': '共享车辆在其生命周期内可以得到更有效的使用，从而实现资源节约型向低碳出行的过渡。我们不断创新和开发我们的服务，以增加共享出行的机会。',
    'SustainabilityEightTitle': '重塑',
    'SustainabilityEightText': '我们拥有车辆开发的内部研发团队，与供应商密切合作，重塑车辆设计并最大限度地减少资源使用。我们已将踏板车的使用寿命延长至五年，并通过智能充电延长了电池寿命。',
    'SustainabilityNineTitle': '维修',
    'SustainabilityNineText': '保养和维修是延长车辆使用寿命的重要组成部分。我们与受过培训的受雇员工合作，他们为提高欧洲备件的保留率做出了贡献。',
    'SustainabilityTenTitle': '重复利用',
    'SustainabilityTenText': '我们尽可能地重复使用组件和备件。例如，几乎我们所有的备件都被重复利用来修理其他车辆或停车架。塑料和橡胶被重新用于我们的停车架和畜栏的触觉条。',
    'Sustainability11Title': '重新利用',
    'Sustainability11Text': '我们翻新旧车辆并将其转售给其他公司以延长其使用寿命。我们还与合作伙伴合作，赋予电池第二次生命，用于储能。',
    'Sustainability12Title': '回收',
    'Sustainability12Text': '当 HRT 价值链内无法重新利用或再利用时，材料将在我们的回收合作伙伴的支持下进行回收。我们的所有废物均通过当地回收合作伙伴进行回收，并且我们致力于实现 0% 的垃圾填埋率。',
    'Sustainability13Title': '可持续发展网络',
    'Sustainability14Title': '我们的气候和社会责任不仅限于我们',
    'Sustainability14Text': '我们与优先考虑负责任实践的供应商和合作伙伴合作。',
    'Sustainability14Btn': '我们的行为准则',
    'ModuleNewsTitle': '最新消息',
    'ModuleNewsText1': 'Malmö法规的重要更新：HRT骑手需要了解的内容',
    'ModuleNewsText2': 'HRT发布年度安全报告，并承诺引领安全报告实现零愿景',
    'ModuleNewsText3': '在美国使用HRT节省美金',
}