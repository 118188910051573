<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {
    mounted() {
      // window.onresize = () => {
      //   // 设置基准值
      //   let sUserAgent = navigator.userAgent.toLowerCase()
      //   let bIsWM = /mobile/.test(sUserAgent)
      //   let baseFontSize = document.documentElement.clientWidth / 375 * 16; // 这里假设设计图宽度为375px，所以基准值为20px
      //   !bIsWM && (baseFontSize = 32)
      //   document.documentElement.style.fontSize = `${baseFontSize}px`;
      // }
    },
    beforeDestroy() {
      localStorage.removeItem('lang')
    }
  }
</script>

<style>
@import '@/assets/css/normalize.scss';
@import "@/assets/css/utils.scss";
@import "@/assets/css/menu.scss";
@import "@/assets/css/grid.scss";
@import "@/assets/css/_index.scss";
@import "@/assets/css/buttons.scss";
@import "@/assets/css/style.css";
.css-1u9p9m2 {
  background-color: #FFFFFF;
  padding: 6.4rem 2.4rem;
  scroll-margin-top: 8rem;
}
@media (min-width: 1280px){
  .css-1u9p9m2 {
    padding: 8rem 6.4rem;
    scroll-margin-top: 9.6rem;
  }
}
.css-frqrno {
  background-color: rgb(242, 105, 97);
  padding: 6.4rem 2.4rem;
  scroll-margin-top: 8rem;
}
@media (min-width: 1280px){
  .css-frqrno {
    padding: 8rem 6.4rem;
    scroll-margin-top: 9.6rem;
  }
}
.css-zuwawv {
  background-color: #FAF4EC;
  padding: 6.4rem 2.4rem;
  scroll-margin-top: 8rem;
}
@media (min-width: 1280px){
  .css-zuwawv {
    padding: 8rem 6.4rem;
    scroll-margin-top: 9.6rem;
  }
}
.css-1akgr1m {
  background-color: #282425;
  padding: 6.4rem 2.4rem;
  scroll-margin-top: 8rem;
}
@media (min-width: 1280px){
  .css-1akgr1m {
    padding: 8rem 6.4rem;
    scroll-margin-top: 9.6rem;
  }
}
.css-13hnd68 {
  width: 100%;
  max-width: 138.4rem;
  margin: 0 auto;
}
.css-zkm6w3 {
  white-space: pre-wrap;
  line-height: 150%;
  color: #000000;
  font-size: 1.6rem;
  letter-spacing: -0.02em;
  font-weight: 200;
  margin-bottom: 2.4rem;
}
.css-zkm6w3:last-child {
  margin-bottom: 0;
}
.css-zkm6w3 a {
  color: rgb(242, 105, 97);
  text-decoration: underline;
  line-height: 150%;
}
@media (min-width: 1280px){
  .css-zkm6w3 {
    margin-bottom: 1.6rem;
  }
  .css-zkm6w3 {
    font-size: 2rem;
    letter-spacing: -0.03em;
  }
  .css-zkm6w3 {
    margin-bottom: 3.2rem;
  }
}
.css-1zmucw {
  max-width: 90rem;
  margin: 0 auto;
}
.css-atx8rn {
  color: #000000;
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.04em;
  text-align: center;
  margin-bottom: 2.4rem;
}
@media (min-width: 1280px){
  .css-atx8rn {
    font-size: 6.4rem;
  }
  .css-atx8rn {
    margin-bottom: 3.4rem;
  }
}
.css-106zc0p {
  text-align: center;
}
@media (min-width: 1280px){
  .css-106zc0p {
    -webkit-column-count: 1;
    column-count: 1;
    gap: 12.8rem;
  }
}


.css-1afuhgt {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 100px;
  font-family: inherit;
  cursor: pointer;
  border: 0.1rem solid transparent;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 120%;
  background-color: #FFFFFF;
  border-color: #B8B4B5;
  height: 6.4rem;
  padding: 1.6rem 2.4rem;
  font-size: 1.8rem;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 2.4rem;
}
@media (min-width: 1280px){
  .css-1afuhgt {
    margin-top: 3.4rem;
  }
}
.css-51q8ui {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-left: 1rem;
}


@media (min-width: 768px){
  .css-aqsecj {
    display: grid;
    grid-template-columns: 30% 1fr;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 6.4rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}
@media (min-width: 1280px){
  .css-aqsecj {
    gap: 12.8rem;
  }
}

.css-8c1spa {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
}
@media (min-width: 768px){
  .css-8c1spa {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
}

.css-1v2xo9w {
  color: #000000;
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.04em;
}
@media (min-width: 1280px){
  .css-1v2xo9w {
    font-size: 6.4rem;
  }
}

.css-1es1obi {
  margin: 0 auto;
  display: block;
  display: block;
  height: 14rem;
  width: 14rem;
  border-radius: 2.4rem;
  object-fit: cover;
}
@media (min-width: 768px){
  .css-1es1obi {
    display: none;
  }
}

 .css-1puitwz p {
   white-space: pre-wrap;
   margin-bottom: 0.8rem;
   color: rgb(0, 0, 0);
   font-size: 1.8rem;
   line-height: 150%;
   letter-spacing: -0.03em;
 }
.css-1puitwz p:last-child {
  margin-bottom: 0px;
}
@media (min-width: 1280px){
  .css-1puitwz p {
    margin-bottom: 1.6rem;
  }
  .css-1puitwz p {
    font-size: 2.4rem;
  }
}

.css-1qajtb1 {
  margin: 0 auto;
  display: block;
  display: none;
}
@media (min-width: 768px){
  .css-1qajtb1 {
    border-radius: 4rem;
    object-fit: cover;
    display: block;
    width: 100%;
    height: auto;
  }
}

.css-1bhvt1q {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 100px;
  font-family: inherit;
  cursor: pointer;
  border: 0.1rem solid transparent;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 120%;
  background-color: #FFFFFF;
  border-color: #B8B4B5;
  height: 4.8rem;
  padding: 1.6rem 2.4rem;
  font-size: 1.6rem;
}
@media (min-width: 1280px){
  .css-1bhvt1q {
    height: 6.4rem;
    padding: 1.6rem 2.4rem;
    font-size: 1.8rem;
  }
}



@media (min-width: 768px){
  .css-6577ju {
    display: grid;
    grid-template-columns: 1fr 30%;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 6.4rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}
@media (min-width: 1280px){
  .css-6577ju {
    gap: 12.8rem;
  }
}

.css-ph6v0f {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
}
@media (min-width: 768px){
  .css-ph6v0f {
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
}

.css-7ldmnw p {
  white-space: pre-wrap;
  margin-bottom: 0.8rem;
  line-height: 150%;
  color: #000000;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: -0.03em;
}
.css-7ldmnw p:last-child {
  margin-bottom: 0;
}
@media (min-width: 1280px){
  .css-7ldmnw p {
    margin-bottom: 1.6rem;
  }
  .css-7ldmnw p {
    font-size: 2.4rem;
  }
}



.css-cbrskf {
  color: #FFFFFF;
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.04em;
  text-align: center;
  margin-bottom: 2.4rem;
}
@media (min-width: 1280px){
  .css-cbrskf {
    font-size: 6.4rem;
  }
  .css-cbrskf {
    margin-bottom: 3.4rem;
  }
}

.css-106zc0p {
  text-align: center;
}
@media (min-width: 1280px){
  .css-106zc0p {
    -webkit-column-count: 1;
    column-count: 1;
    gap: 12.8rem;
  }
}


.css-1tlmnck {
  white-space: pre-wrap;
  margin-bottom: 0.8rem;
  line-height: 150%;
  color: #FFFFFF;
  font-size: 1.6rem;
  line-height: 150%;
  letter-spacing: -0.02em;
  font-weight: 200;
  margin-bottom: 2.4rem;
}
.css-1tlmnck:last-child {
  margin-bottom: 0;
}
@media (min-width: 1280px){
  .css-1tlmnck {
    margin-bottom: 1.6rem;
  }
  .css-1tlmnck {
    font-size: 2rem;
    letter-spacing: -0.03em;
  }
  .css-1tlmnck {
    margin-bottom: 3.2rem;
  }
}

 .css-11huiyq {
   color: rgb(255, 255, 255);
   font-size: 3.6rem;
   font-weight: 600;
   line-height: 120%;
   letter-spacing: -0.04em;
 }
@media (min-width: 1280px){
  .css-11huiyq {
    font-size: 6.4rem;
  }
}

 .css-5vaa2y p {
   white-space: pre-wrap;
   margin-bottom: 0.8rem;
   color: rgb(255, 255, 255);
   font-size: 1.8rem;
   line-height: 150%;
   letter-spacing: -0.03em;
 }
.css-5vaa2y p:last-child {
  margin-bottom: 0px;
}
@media (min-width: 1280px){
  .css-5vaa2y p {
    margin-bottom: 1.6rem;
  }
  .css-5vaa2y p {
    font-size: 2.4rem;
  }
}

.css-5uxhph {
  color: #000000;
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.04em;
  margin-bottom: 2.4rem;
  max-width: 70rem;
}
@media (min-width: 1280px){
  .css-5uxhph {
    font-size: 6.4rem;
  }
  .css-5uxhph {
    margin-bottom: 3.2rem;
  }
}

 .css-147xdo3 {
   border-radius: 2.4rem;
   padding: 3.6rem 2.4rem;
   background-color: rgb(255, 255, 255);
   box-shadow: rgba(0, 0, 0, 0.1) 0px 1rem 2rem 0px;
   border: none;
   display: flex;
   -webkit-box-align: center;
   align-items: center;
   gap: 4rem;
   margin-bottom: 2.4rem;
   flex-direction: column;
 }
@media (min-width: 768px){
  .css-147xdo3 {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1280px){
  .css-147xdo3 {
    border-radius: 4rem;
    padding: 4.8rem 3.6rem;
  }
}

 .css-1qrkzow {
   margin: 0px auto;
   display: block;
   border-radius: 0px;
   width: 100%;
   height: auto;
 }
@media (min-width: 768px){
  .css-1qrkzow {
    width: 35rem;
    height: 35rem;
  }
}

 .css-1ex9xf2 {
   color: rgb(0, 0, 0);
   font-size: 2.4rem;
   font-weight: 600;
   line-height: 120%;
   letter-spacing: -0.03em;
   margin-bottom: 1.6rem;
 }
@media (min-width: 1280px){
  .css-1ex9xf2 {
    font-size: 4.8rem;
    letter-spacing: -0.04em;
  }
}
.css-kxpubt {
  text-overflow: ellipsis;
}
.css-kxpubt p {
  white-space: pre-wrap;
  margin-bottom: 0.8rem;
  line-height: 150%;
  color: #000000;
  font-size: 1.6rem;
  line-height: 150%;
  letter-spacing: -0.02em;
}
.css-kxpubt p:last-child {
  margin-bottom: 0;
}
@media (min-width: 1280px){
  .css-kxpubt p {
    margin-bottom: 1.6rem;
  }
  .css-kxpubt p {
    font-size: 2rem;
    letter-spacing: -0.03em;
  }
}

</style>
