export default {
    'HeaderNav1': 'Ездить верхом',
    'HeaderNav2': 'Видение',
    'HeaderNav3': 'Помощь',
    'HeaderNav4': 'Блог',
    'HeaderText1': 'Скачать',
    'HeaderText3': 'Вернуться на главную страницу',
    'HeaderText2': 'Получить приложение HRT',
    'LangChoose': 'Выбор языка',
    'SectionOneTitle': 'Давайте переосмыслим наш город!',
    'SectionOneText': 'Получить приложение HRT',
    'SectionOneTip': 'Ваш браузер не поддерживает разметку видео.',
    'SectionTwoTitle': 'Более умный способ путешествовать по городу',
    'SectionTwoText': 'Просто нажмите на телефон, чтобы арендовать электрический скутер или электрический велосипед от HRT и добраться до любой точки города за несколько минут. Это идеальный способ исследовать новые места или просто испытать свой родной город с разных точек зрения.',
    'SectionTwoBtn': 'Как это работает?',
    'SectionThreeTitle1': 'Совместное движение',
    'SectionThreeTitle2': 'Лучшее завтра',
    'SectionThreeItemOneTitle1': 'Мы воспитываем',
    'SectionThreeItemOneTitle2': 'Наши пользователи',
    'SectionThreeItemOneText': 'Знания имеют решающее значение для обеспечения безопасной и устойчивой транспортной среды',
    'SectionThreeItemTwoTitle1': 'Мы и город',
    'SectionThreeItemTwoTitle2': 'Сотрудничество',
    'SectionThreeItemTwoText': 'Потому что реальные, долгосрочные изменения происходят только тогда, когда мы работаем вместе.',
    'SectionThreeItemThreeTitle1': 'Мы...',
    'SectionThreeItemThreeTitle2': 'Сочетание с общественным транспортом',
    'SectionThreeItemThreeText': 'Наши услуги работают с более широкой транспортной экосистемой',
    'SectionFourTitle': 'Город для проживания',
    'SectionFourText': 'В HRT мы вновь ставим людей в центр городской трансформации. Наше видение заключается в том, чтобы будущие городские жители жили и путешествовали в безопасных, здоровых, менее загрязняющих, менее шумных и менее стрессовых условиях.。',
    'SectionFourBtn': 'Понимание нашего видения.',
    'SectionFiveTitle': 'Последние новости',
    'SectionFiveItemOneTitle': 'HRT публикует отчеты по безопасности, чтобы донести до городов основное послание: давайте работать вместе, чтобы все было хорошо',
    'SectionFiveItemTwoTitle': 'Руководство по парковке электрических скутеров HRT',
    'SectionFiveItemThreeTitle': 'Климатические цели HRT одобрены научно обоснованной целевой инициативой',
    'FooterItemOneTitle': 'Всадник.',
    'FooterItemOneText1': 'Как это работает?',
    'FooterItemOneText2': 'Безопасная езда',
    'FooterItemOneText3': 'Цены и пропуска',
    'FooterItemOneText4': 'Как остановить машину',
    'FooterItemOneText5': 'Центр помощи',
    'FooterItemTwoTitle': 'Города и партнеры',
    'FooterItemTwoText1': 'Городская продукция',
    'FooterItemTwoText2': 'Наш метод',
    'FooterItemTwoText3': 'Безопасность дорожного движения',
    'FooterItemTwoText4': 'Решение парковки',
    'FooterItemTwoText5': 'Мобильные партнеры',
    'FooterItemThreeTitle': 'Компания',
    'FooterItemThreeText1': 'О нас',
    'FooterItemThreeText2': 'Местонахождение',
    'FooterItemThreeText3': 'Устойчивое развитие',
    'FooterItemThreeText4': 'Профессиональные возможности',
    'FooterItemThreeText5': 'Нажми',
    'FooterItemFourTitle': 'Ресурсы',
    'FooterItemFourText1': 'Условия использования',
    'FooterItemFourText2': 'Политика конфиденциальности',
    'FooterItemFourText3': 'Печенье',
    'FooterItemFourText4': 'Публикации',
    'FooterItemFourText5': 'Заявление о современном рабстве',
    'FooterDesign': 'Разработан шведским HRT.',
    'chooseLanguage': 'Выбор языка',
    'rideFirstModuleTitle': 'Как это работает?',
    'rideFirstModuleText': 'Перемещение по городу никогда не было таким простым или удобным. Узнайте, как начать прокрутку несколькими простыми шагами и добраться до места назначения за несколько минут. Загрузить это программное обеспечение',
    'rideSecondModuleTitle': 'Начните немедленно:',
    'rideSecondModuleText1-1': 'Скачать приложение',
    'rideSecondModuleText1-2': 'Бесплатно',
    'rideSecondModuleText1-3': 'И зарегистрировать аккаунт.',
    'rideSecondModuleText2-1': 'Использование внутренних карт',
    'rideSecondModuleText2-2': 'Найдите электрический скутер или электрический велосипед поблизости.',
    'rideSecondModuleText3-1': 'Сканирование',
    'rideSecondModuleText3-2': 'Автомобиль разблокировал QR - код.',
    'rideSecondModuleText4-1': 'На автобусе,',
    'rideSecondModuleText4-2': 'Всего за несколько минут можно добраться до любой точки города.',
    'rideThreeModuleTitle': 'Электрический скутер или электрический велосипед?',
    'rideThreeModuleText1': 'Когда вам нужно быстро добраться куда - то на более короткое расстояние, наши электрические скутеры идеально подходят, а наши электрические велосипеды идеально подходят для более длинных маршрутов.',
    'rideThreeModuleText2': 'Если вы новичок в электрическом скутере, активируйте режим замедления в приложении. Это ограничивает максимальную скорость скутера и позволяет вам медленно начинать, когда вы учитесь управлять автомобилем.',
    'rideFourModuleTitle': 'Кто может ездить на электрическом скутере?',
    'rideFourModuleText1': 'Вы должны быть старше 18 лет и иметь действительные водительские права, чтобы арендовать электрический скутер в США.',
    'rideFourModuleText2': 'Поэтому после регистрации вам нужно будет загрузить фотографию водительского удостоверения и сфотографировать себя, чтобы подтвердить свою личность.',
    'rideFiveModuleTitle': 'Конная зона',
    'rideFiveModuleText': 'Наши транспортные средства реагируют на заранее установленные правила в зависимости от их местоположения. Все города с HRT разделены на виртуальные зоны с различными требованиями. Перед отъездом обязательно ознакомьтесь с каждым из них.',
    'rideSixModuleTitle1': 'Парковка +',
    'rideSixModuleText1': 'Отличное место для парковки. На самом деле, отлично, мы дадим вам скидку, чтобы поблагодарить вас за парковку здесь.',
    'rideSixModuleTitle2': 'Парковка',
    'rideSixModuleText2': 'В некоторых городах парковка разрешается только в специально отведенных для этого местах. Перед отъездом используйте карту, чтобы найти парковку возле места назначения.',
    'rideSixModuleTitle3': 'Парковка запрещена.',
    'rideSixModuleText3': 'Некоторые места просто не подходят для парковки. Вот почему у нас есть зона, где парковка запрещена, и вы не сможете завершить свое путешествие.',
    'rideSevenModuleTitle1': 'зона низких скоростей',
    'rideSevenModuleText1': 'Низкая скорость - это область, которая обычно заполнена автомобилями или пешеходами. Поэтому здесь автомобиль будет немного замедляться автоматически.',
    'rideSevenModuleTitle2': 'Зона запрета верховой езды',
    'rideSevenModuleText2': 'Как следует из названия, это запретная зона для всех автомобилей HRT. Здесь вы не сможете ездить или припарковаться на электрическом скутере или электрическом велосипеде.',
    'rideSevenModuleTitle3': 'За пределами района операций',
    'rideSevenModuleText3': 'Транспортные средства HRT находятся в зоне эксплуатации. Если вы уедете с одним автомобилем, автомобиль полностью остановится, и вы не сможете завершить путешествие.',
    'rideEightModuleTitle': 'Парковка - что применимо?',
    'rideEightModuleText1': 'Как и в разных районах езды, правила парковки в разных городах различны - обязательно проверьте приложение, чтобы узнать, как это работает в вашем районе.',
    'rideEightModuleText2': 'Правильная парковка связана не только с красотой, но и с красотой. Прежде всего, это вопрос безопасности и доступности.',
    'rideEightModuleBtn': 'Узнать больше о парковке',
    'RideNineModuleTitle': 'Цены и пропуска',
    'RideNineModuleText': 'Получите больше опыта верховой езды за меньшую плату, подписавшись на месяц, получая однодневный пропуск или оплачивая его напрямую по требованию. Цены варьируются в зависимости от города - проверьте приложение HRT, чтобы узнать точную цену, которая применяется в вашем регионе.',
    'RideNineModuleBtn': 'Посмотреть цены',
    'RideTenModuleTitle': 'Есть вопросы?',
    'RideTenModuleText': 'Найдите ответы на наиболее часто задаваемые вопросы в нашем центре помощи, где вы также найдете множество советов, советов и полезной информации о наших услугах.',
    'RideTripModuleText1': 'Безопасная езда',
    'RideTripModuleText2': 'Останови машину.',
    'RideTripModuleText3': 'Автотранспортные средства',
    'RideTenModuleBtn': 'Посетить Центр помощи',
    'moreFirstModuleTitle': 'Парковка становится простой.',
    'moreFirstModuleText': 'Это руководство, которое вы искали.',
    'moreSecondModuleText': 'В HRT мы верим, что безопасные и инклюзивные города созданы для жизни. Поэтому мы настоятельно призываем вас всегда правильно парковаться и помочь сохранить тротуар доступным пространством для всех.',
    'moreThreeModuleTitle1': 'Во - первых, базовые знания:',
    'moreThreeModuleTitle2': 'Понимание местных правил',
    'moreThreeModuleText': 'Пожалуйста, будьте в курсе местных правил и положений, касающихся парковки автомобилей, и соблюдайте их. Пожалуйста, продолжайте читать для получения дополнительной информации.',
    'moreFourModuleTitle': 'Будьте внимательны.',
    'moreFourModuleText': 'Обеспечить, чтобы никакие контрольно - пропускные пункты или пешеходные переходы не блокировались или каким - либо иным образом не препятствовали прохождению пешеходов или других транспортных средств.',
    'moreFiveModuleTitle': 'Разные города - разные правила',
    'moreFiveModuleText': 'Как правило, применяется один из следующих элементов:',
    'moreSixModuleTitle': 'Зона принудительной парковки',
    'moreSixModuleText': 'В некоторых городах парковка разрешена только на автостоянках и других местах, предназначенных для парковки электрических скутеров и велосипедов. Если вы находитесь в таком городе, эти обязательные парковочные места будут отмечены синим цветом в приложении (« Парковочная зона»). Перед отъездом используйте карту приложения, чтобы найти парковочную зону вблизи места назначения.',
    'moreSevenModuleTitle': 'Свободно плавающая парковка',
    'moreSevenModuleText': 'В других городах парковка разрешена в большинстве районов эксплуатации, за исключением так называемых зон, где парковка и езда запрещены, отмеченных на внутренней карте. Здесь вам нужно припарковать автомобиль параллельно и рядом со стеной, на раме или в специально отведенном месте для парковки.',
    'moreEightModuleTitle': 'Комплексные правила парковки',
    'moreEightModuleText1': 'В некоторых городах действуют различные законы. Это обычно означает, что в центре города есть зоны обязательной парковки, в то время как в соответствии с режимом свободного плавания парковка за пределами центра разрешена.',
    'moreEightModuleText2': 'Ознакомьтесь с приложением, чтобы узнать больше о конкретных правилах парковки и правилах, применимых в вашем районе.',
    'MoreNineModuleTitle': 'Важность правильной парковки',
    'MoreNineModuleText1': 'Правильная парковка - это не только красота; И самое главное,',
    'MoreNineModuleText2': 'Это вопрос безопасности и доступности.',
    'MoreNineModuleText3': 'Узнайте больше о том, как мы можем улучшить безопасную парковку в городе с помощью первоклассных технологий',
    'MoreNineModuleText4': 'Решения',
    'MoreNineModuleText5': 'Несоблюдение местных правил парковки нарушает наши правила.',
    'MoreNineModuleText6': 'Пользовательское соглашение',
    'MoreNineModuleText7': 'Это может привести к штрафу.',
    'MoreTenModuleTitle': 'Видишь машину не в том месте?',
    'MoreTenModuleText1': 'Если вы видите один из наших электрических скутеров или велосипедов, припаркованных в неподходящем месте, пожалуйста',
    'MoreTenModuleText2': 'Сообщите об этом нашей команде,',
    'MoreTenModuleText3': 'Чтобы они могли действовать быстро.',
    'MoreTripModuleText1': 'Безопасная езда ',
    'MoreTripModuleText2': 'Как это работает?',
    'MoreTripModuleText3': 'Устойчивое развитие',
    'PriceFirstModuleTitle': 'Цены и пропуска',
    'PriceFirstModuleText': 'Простое ценообразование, неограниченные возможности. Выберите лучшее для вас!',
    'PriceSecondModuleTitle': 'Выберите свой ценовой план',
    'PriceThreeModuleTitle': 'Подходит для езды время от времени.',
    'PriceThreeModuleText1': 'Варианты ценообразования с оплатой по объему идеально подходят для пассажиров, совершающих случайные короткие поездки.',
    'PriceThreeModuleText2': 'Вы должны заплатить около $1 за разблокировку автомобиля, а затем от $0,14 до $0,20 в минуту.',
    'PriceThreeModuleText3': 'Пример цены: в среднем 10 - минутная поездка с оплатой по требованию в США стоит около $2,5 (до применения какой - либо скидки).',
    'PriceThreeModuleBtn': 'Попробуйте платить по объему.',
    'PriceFourModuleTitle': 'Один день на двух колесах',
    'PriceFourModuleText1': 'С дневным билетом HRT вам не придется платить обычную плату за разблокировку, когда вы начинаете ездить, и вы можете использовать около 100 - 120 минут езды в течение 24 часов.',
    'PriceFourModuleText2': 'В США дневной пропуск обычно стоит от 7 до 10 долларов. Он идеально подходит для изучения новых или известных городов.',
    'PriceFourModuleText3': 'Пример цены: если вы используете однодневный билет, чтобы совершить около пяти поездок на аттракцион, средняя стоимость поездки составляет около 1,5 долларов США.',
    'PriceFourModuleBtn': 'Получить однодневный пропуск',
    'PriceFiveModuleTitle': 'Для тех, кто часто ездит.',
    'PriceFiveModuleText1': 'С ежемесячной подпиской на HRT Pass перейдите к зеленому и сэкономите деньги. С этим пропуском вам не нужно платить за разблокировку, и вы можете выбрать количество минут езды в месяц.',
    'PriceFiveModuleText2': 'Опции и стоимость ежемесячной подписки варьируются в зависимости от места. Например, в США подписка, включающая 300 минут, обычно стоит около 35 долларов в месяц. Таким образом, вы можете совершить около 30 10 - минутных заездов или 20 15 - минутных заездов.',
    'PriceFiveModuleText3': 'Пример цены: в среднем, стоимость поездки в США с использованием месячного билета составляет от 0,7 до 1 доллара США',
    'PriceFiveModuleBtn': 'Получить месячный билет',
    'PriceSixModuleTitle': 'Использование HRT Pass всегда бесплатно',
    'PriceSixModuleText': 'Если у вас закончились все минуты дневного или месячного билета, не волнуйтесь! Вы по - прежнему можете разблокировать все аттракционы бесплатно и оплачивать только стандартные минуты, пока ваш пропуск не истек или не будет автоматически продлен. Перейдите в приложение, чтобы увидеть точную цену вашего местоположения и прочитать полные условия.',
    'PriceSixModuleBtn': 'Открыть приложение HRT',
    'PriceSevenModuleTitle': 'Способы оплаты',
    'PriceSevenModuleText1': 'Мы принимаем различные популярные способы оплаты, а также некоторые из любимых местных способов оплаты.',
    'PriceSevenModuleText2': 'Скачать',
    'PriceSevenModuleText3': 'Приложение',
    'PriceSevenModuleText4': 'Прыгай в машину.',
    'PriceEightModuleTitle': 'Безопасная езда и разблокирование эксклюзивных предложений',
    'PriceEightModuleText': 'Получите награду за завершение нашей онлайн - школы дорожного движения, парковку автомобиля в указанной зоне награждения или фотографирование себя в шлеме.',
    'PriceEightModuleBtn': 'Учиться безопасной езде',
    'PriceNineModuleText1': 'Цены, продукты, функции и рекламные кампании, упомянутые в настоящем документе, могут меняться и могут варьироваться в зависимости от таких факторов, как место, время, тип транспортного средства и другие обстоятельства. Предоставляемая информация предназначена исключительно для общего пользования и не полностью отражает конкретные цены, рекламные акции, способы оплаты или продукты, доступные и применимые в вашем городе, регионе или стране.',
    'PriceNineModuleText2': 'Цены, упомянутые на этой странице, являются приблизительными и могут потребовать дополнительных сборов и других сборов в соответствии с местными правилами и требованиями. Вы несете ответственность за ознакомление с любыми такими дополнительными расходами перед использованием Услуги.',
    'PriceNineModuleText3': 'Чтобы убедиться, что вы получаете самую точную и актуальную информацию о ценах, ознакомьтесь с приложением HRT, чтобы узнать о конкретных ценах и рекламных кампаниях в вашем регионе.',
    'PriceTripModuleTitle1': 'Как это работает?',
    'PriceTripModuleTitle2': 'Безопасная езда',
    'PriceTripModuleTitle3': 'Парковка',
    'HelpHeaderTitle': 'Мы можем вам помочь?',
    'HelpHeaderText': 'Поиск статей помощи',
    'HelpCategoryText1': 'Вход',
    'HelpCategoryText2': 'Счета и выплаты',
    'HelpCategoryText3': 'Поможем в поездке.',
    'HelpCategoryText4': 'Руководство по парковке',
    'HelpCategoryText5': 'Безопасность и условия',
    'HelpCategoryText6': 'Долгосрочная аренда (Великобритания)',
    'BlogHeaderTitle': 'Блог HRT',
    'BlogHeaderText': 'Новости, обновления и идеи от движения MicroMobile.',
    'BlogNewsTitle1': 'HRT объединяет усилия с европейскими лидерами мобильности для создания рабочей группы по мобильности будущего',
    'BlogNewsOneText1': 'Являясь ключевым игроком в области путешествий в Европе, HRT работает над продвижением устойчивых транспортных решений и вносит свой вклад в развитие общих поездок. Руководствуясь духом сотрудничества и инноваций, мы рады объявить о своем вступлении в Рабочую группу ЕС по будущей мобильности.',
    'BlogNewsOneText2': 'В январе 2024 года рабочая группа совместно с Комиссаром ЕС по транспорту Адиной V ă Компания LEAN объединила 18 влиятельных компаний и стартапов в области общих поездок, зарядки электромобилей, магнитной левитации, железных дорог, автобусов, грузовых перевозок и воздушного движения. Наша общая цель - предоставить политикам ценную информацию для разработки устойчивых и доступных решений для мобильной связи в существующих транспортных сетях.',
    'BlogNewsOneText3': 'Вместе с такими уважаемыми партнерами, как Bolt, Cabify, Flix и Volocopter, HRT стремится формировать будущее транспорта. Разработав всеобъемлющий набор рекомендаций, мы стремимся внести свой вклад в высвобождение экономических, социальных и экологических выгод, присущих индустрии путешествий. Эти предложения будут представлены комиссару Валину весной.',
    'BlogNewsOneText4': 'Дуглас Старк (Douglas Stark), главный операционный директор HRT и член - основатель рабочей группы, сказал: « Мы взволнованы потенциальными последствиями и с нетерпением ждем сотрудничества с другими лидерами отрасли в этом путешествии перемен».',
    'BlogNewsTitle2': 'Повторный тендер на испытание электрического скутера в Великобритании: размышления HRT о том, как правильно его реализовать',
    'BlogNewsTwoText1': 'Испытания электрических скутеров в Великобритании продолжаются уже более трех лет. В ноябре 2023 года правительство не объявило о долгосрочном законодательстве, которое закрепит микротранспорт в нашей транспортной системе, но снова продлило его до мая 2026 года. Некоторые парламенты и региональные власти, которые управляют местными испытаниями электрических скутеров, пересматривают существующие контракты, чтобы решить, следует ли продлевать условия с существующими поставщиками или проводить торги для новых контрактов.',
    'BlogNewsTwoText2': 'Один журналист недавно сообщил о переходе нового микромобильного оператора в Западной Англии, отметив, что « если он не плох, зачем его ремонтировать? » Многие посты в социальных сетях пометили нас и попросили вернуться в Бристоль, где мы построили и эксплуатировали одну из самых успешных программ электрических скутеров в Европе до тех пор, пока в октябре не произошли изменения в операторе. В социальных сетях и новостях сообщалось, что наши бывшие велосипедисты жаловались на нехватку сопровождающих скутеров на улицах, отсутствие долгосрочной аренды скутеров, отсутствие парковочных мест, а также задавали вопросы о обещанных электрических и грузовых велосипедах, но к началу декабря новый контракт так и не был реализован.',
    'BlogNewsTwoText3': 'Это заставляет нас задуматься о том, как местные власти и операторы микротранспорта должны сотрудничать, чтобы любые изменения в операторах были как можно более плавными и, что самое главное, минимизировали помехи для обслуживания пассажиров. Успех экспериментов означает, что люди полагаются на них, чтобы работать, получать образование, проводить деловые свидания, ходить в магазины и отдыхать. В случае перебоев в обслуживании - либо отсутствие скутеров в течение нескольких месяцев, либо значительное сокращение числа скутеров - гонщики вынуждены искать другой источник трафика. К сожалению, мы видим в социальных сетях сообщения о том, что некоторые люди решили снова начать пользоваться автомобилем.',
    'BlogNewsTwoText4': 'Опираясь на опыт трансформации, в которой мы участвовали в этом году в Великобритании, включая начало работы в Лондоне, Чертенхеме и Глостере. Это наши идеи о лучшей практике.',
    'BlogNewsTwoText5': 'Хороший контрактный дизайн очень важен.',
    'BlogNewsTwoText6': 'Парламенты не должны рассматривать электрические скутеры как краткосрочное денежное дерево и помнить, что мы - единственная устойчивая форма транспорта, которая не получает государственных субсидий или капиталовложений. Мы инвестируем в склады, персонал и оборудование; Это капиталоемкая отрасль с низкой прибылью. Чтобы быть финансово устойчивыми, нам нужны контракты, которые являются финансово устойчивыми; Это означает, что следует избегать слепых аукционов с долей дохода. Мы не можем позволить себе потерять наш бизнес - план, а требуемая часть дохода подвергает услуги риску. Такой подход может привести к краху операторов, вынужденному перераспределению закупок парламентами и потере гражданами транспортных возможностей, от которых они зависят.',
    'BlogNewsTwoText7': 'Необходимо проверить, сможет ли оператор выполнить свои обязательства.',
    'BlogNewsTwoText8': 'Мы всегда рады, что местные власти посещают один из наших складов, чтобы узнать, как они работают, проверить наше здоровье и безопасность, посмотреть на работу наших механиков и попробовать наши электрические скутеры и электрические велосипеды. Мы можем продемонстрировать и объяснить, как мы можем управлять финансово устойчивыми и ответственными услугами. Таким образом, они увидят, что мы сможем выполнить наши обязательства и обеспечить, чтобы не было никаких сокращений.',
    'BlogNewsTwoText9': 'Сотрудничество - это ключ.',
    'BlogNewsTwoText10': 'Обеспечить, чтобы контракты были применимы ко всем - пассажирам, парламентам, операторам и широкой общественности, в интересах всех. Лучшим способом достижения этой цели является тесное сотрудничество операторов с местными властями, хорошо осведомленными о сообществе, и получение отзывов от пассажиров и других участников дорожного движения или пешеходов, которые разделяют дороги и пути с нашими транспортными средствами.',
    'BlogNewsTwoText11': '* Мэттью Пенчарц (Matthew Pencharz) является руководителем государственной политики HRT в Великобритании, Ирландии и Франции. Он выразил удовлетворение в связи с продолжением диалога с местными властями в целях проведения торгов на предоставление микротранспортных услуг в этом районе.',
    'BlogNewsReadMore': 'Читать далее',
    'BlogNewsReadShare': 'Поделиться',
    'VisionFirstTitle': 'В настоящее время более половины населения мира проживает в городах.',
    'VisionFirstText1': 'К 2050 году этот показатель достигнет двух третей. По мере того, как все больше людей переезжают из сельских районов в города, на дорогах будет все больше автомобилей, все больше и больше горячих точек пробок вблизи домов и рабочих мест и меньше зеленых насаждений.',
    'VisionFirstText2': 'Если у нас не будет достаточного мужества, чтобы бросить вызов статус - кво и действовать.',
    'VisionSecondText1': 'Наше видение:',
    'VisionSecondText2': 'Города, рожденные для жизни, свободны от шума и загрязнения.',
    'VisionThreeText1': 'В HRT уверены, что в будущем городские дороги будут вновь заняты людьми. Мы хотим, чтобы люди вернулись в центр городской трансформации с целью создания завтрашнего дня, когда люди будут жить и действовать в безопасной, здоровой, менее загрязненной, менее шумной и более пространственной среде.',
    'VisionThreeText2': 'Будущее, которое мы видим, - это устойчивое будущее, цикл - это норма, а совместное путешествие - это путь развития. Отдельные лица, компании и правительства берут на себя ответственность за уменьшение воздействия на климат, помогают защитить нашу планету и способствуют созданию более пригодных для жизни городов для всех.',
    'VisionThreeBtn': 'Заявление о концепции',
    'VisionFourText': 'Загляните в будущее вашего города',
    'VisionFiveTitle': 'Канада - Монреаль',
    'VisionFiveText1': 'Центр Монреаля - оживленная магистраль с пятиполосным движением. Наше предложение с Jaja Architects направлено на создание зеленого позвоночника в Монреале, а не загрязненной дороги.',
    'VisionFiveText2': 'Зеленый позвоночник будет отдавать приоритет ходьбе, езде на велосипеде, микротранспорте, общественном транспорте и грузовых велосипедах. Кроме того, он предоставит людям пространство, чтобы наслаждаться менее сумасшедшим и более динамичным центром города.',
    'VisionSixTitle': 'Великобритания - Лондон - Биг Бенн',
    'VisionSixText1': 'Транспортные коридоры между центром Лондона и пригородами в основном основаны на автомобилях, что препятствует развитию микротранспорта.',
    'VisionSixText2': 'Чтобы решить эту проблему, лондонское городское уличное предложение предлагает сократить односторонние полосы и создать транспортный узел, соединяющий микротранспорт и автобусы. Также включает в себя велосипедную дорожку, которая отделяет от движения « гибкую зону», созданную путем демонтажа 50% существующих парковочных мест.',
    'VisionSix1Title': 'Лос - Анджелес - Золотой Берег',
    'VisionSix1Text1': 'Большая часть транспортных и развлекательных мероприятий на Золотом побережье проходит пешком. Для развития этого района компания проконсультировалась с соответствующими правительственными чиновниками и получила разрешение правительства на использование велосипедов с общим балансом на этих улицах. Наша продукция приносит больше удобств туристам. Общий баланс велосипедов не ограничен пешеходными улицами. Продукт максимально помогает каждому.',
    'VisionSevenTitle': '15 минут в городе',
    'VisionSevenText1': 'Новая парадигма городского развития подчеркивает необходимость удовлетворения повседневных потребностей в радиусе 15 минут пешком, на велосипеде или общественном транспорте. HRT поддерживает эту концепцию городского развития и считает, что совместный микротранспорт играет важную роль в укреплении сети общественного транспорта.',
    'VisionEightTitle': 'Наша миссия:',
    'VisionEightText1': 'Обеспечение безопасного, устойчивого и надежного микротранспорта для всех.',
    'VisionNineTitle': 'Чтобы реализовать наше видение, мы предлагаем безопасные, доступные и простые в использовании легкие мобильные транспортные средства, которые позволяют людям путешествовать по городу по своему усмотрению. Мы считаем, что благодаря более широкому доступу к устойчивым транспортным вариантам мы можем улучшить качество жизни в городах по всему миру, а также внести свой вклад в борьбу с изменением климата.',
    'VisionNineText1': 'Например, сочетание автобусов и скутеров может уменьшить препятствия и общее время в пути, делая общественный транспорт жизнеспособной альтернативой автомобилям. Это особенно важно в контексте поездок на работу, будь то работа, встреча с родственниками или посещение галереи с друзьями.',
    'VisionTenTitle': 'Более тесная связь между микротранспортом и общественным транспортом приносит пользу городам',
    'VisionTenText1': 'Совместная программа HRT, Mobimeo и немецкого железнодорожного оператора S - Bahn Stuttgart доказала свою эффективность в улучшении парковки электрических скутеров и содействии продаже билетов на общественный транспорт. Проект является многообещающим шагом в направлении взаимовыгодной интеграции между поставщиками микротранспорта и общественного транспорта.',
    'VisionTenBtn': 'Читать тематические исследования',
    'VisionTextTitle': 'Интеграция с другими транспортными услугами',
    'VisionTextText1': 'HRT предлагает эффективный способ расширить охват и производительность существующих систем общественного транспорта, которые в противном случае потребуют значительных инвестиций в инфраструктуру.',
    'VisionTextText2': 'Наша платформа для прямого использования использует стандартные интерфейсы программирования приложений (API) для общих мобильных операторов, таких как GBFS и MDS, что позволяет легко интегрировать наши услуги с приложениями общественного транспорта, платформами планирования маршрутов и мобильными сервисами (MaaS).',
    'VisionTextBtn': 'Читать далее',
    'VisionSchemeTitle': 'Наше путешествие только начинается.',
    'VisionSchemeText1': 'Следите за нашим блогом, чтобы узнать последние новости и мнения о HRT Technology.',
    'VisionSchemeBtn': 'Читайте наш блог',
    'AboutOneTitle': 'Реконструкция вашего города.',
    'AboutOneText': 'Города созданы для жизни без шума и загрязнения',
    'AboutTwoTitle': 'О нас',
    'AboutTwoText1': 'HRT является одной из самых быстрорастущих мобильных компаний в мире.',
    'AboutTwoText2': 'Мы родились в Соединенных Штатах, продвигая инновации с помощью самых современных аппаратных и программных решений, которые формируют будущее устойчивого транспорта и городского развития.',
    'AboutTwoText3': 'Испытайте удобство использования нашего совместного электромобиля из точки A в точку B, одновременно уменьшая загрязнение воздуха и шума и уменьшая пробки на дорогах по всей Европе.',
    'AboutThreeTitle': 'Для всадников',
    'AboutThreeText1': 'Благодаря нашему приложению HRT, HRT предоставляет первоклассные электромобили общего пользования по требованию, чтобы сделать передвижение по городу более удобным.',
    'AboutThreeText2': 'Просто используйте карту внутри приложения, чтобы найти близлежащий электрический скутер или электрический велосипед, сканируйте QR - код на ручке мобильного телефона, чтобы разблокировать автомобиль и начать легко и свободно исследовать город.',
    'AboutMoreBtn': 'Узнать больше',
    'AboutFourTitle': 'Для городов и городов',
    'AboutFourText1': 'Совместно с городскими чиновниками и организациями HRT работает над решением городских проблем, предлагая индивидуальные решения для удовлетворения конкретных транспортных потребностей и возможностей в вашем регионе.',
    'AboutFourText2': 'Наш подход делает HRT надежным и надежным партнером для города, о чем свидетельствует наш статус оператора с наибольшим количеством скутеров в европейских лицензионных и тендерных проектах.',
    'AboutFiveTitle': 'Для транспортных услуг',
    'AboutFiveText1': 'HRT предлагает эффективный способ расширить охват и производительность существующих систем общественного транспорта, которые в противном случае потребуют значительных инвестиций в инфраструктуру.',
    'AboutFiveText2': 'Наша платформа для прямого использования использует стандартный интерфейс прикладного программирования (API) для общих операторов путешествий, который позволяет легко интегрировать наши услуги с общественным транспортом, платформами планирования маршрутов и приложениями MaaS.',
    'AboutSixTitle': 'Наше видение: создание безопасных для жизни городов без шума и загрязнения',
    'AboutSixText1': 'В HRT уверены, что в будущем городские дороги будут вновь заняты людьми.',
    'AboutSixText2': 'Мы вернули людей в центр городских преобразований, чтобы создать будущее, в котором люди будут жить и действовать в безопасной, здоровой, менее загрязненной, менее шумной и более пространственной среде.',
    'AboutSixText3': 'Мы считаем, что благодаря более широкому доступу к устойчивым транспортным вариантам мы можем улучшить качество жизни в городах по всему миру, а также внести свой вклад в борьбу с изменением климата.',
    'AboutSixBtn': 'Наше видение',
    'AboutEightTitle': 'Наша миссия - обеспечить безопасный, устойчивый и надежный микротранспорт для всех',
    'AboutEightText1': 'Чтобы реализовать наше видение, мы предлагаем безопасные, доступные и простые в использовании легкие мобильные транспортные средства, которые позволяют людям путешествовать по городу по своему усмотрению.',
    'AboutEightText2': 'Снижая зависимость от автомобилей и улучшая доступ к устойчивому транспорту, мы можем помочь создать лучшие и более безопасные города для всех.',
    'AboutEightBtn': 'Как это работает?',
    'AboutNineTitle': 'Микротранспорт помогает сократить выбросы углерода в городских районах',
    'AboutNineText1': 'Предоставляя альтернативные виды транспорта и повышая доступность общественного транспорта, микротранспорт может помочь городам достичь своих климатических целей. Мы применяем комплексный подход к минимизации воздействия на окружающую среду и постоянно совершенствуем нашу устойчивую практику.',
    'AboutTenTitle': 'Микротранспорт помогает сократить выбросы углерода в городских районах',
    'AboutTenText1': 'Предоставляя альтернативные виды транспорта и повышая доступность общественного транспорта, микротранспорт может помочь городам достичь своих климатических целей. Мы применяем комплексный подход к минимизации воздействия на окружающую среду и постоянно совершенствуем нашу устойчивую практику.',
    'About11Title': 'Наша команда - наша самая большая сила.',
    'About11Text1': 'В HRT наша команда столь же разнообразна, как и сообщества, которые мы обслуживаем, и ведет к более устойчивым видам транспорта.',
    'About11Text2': 'Независимо от того, поддерживаете ли вы наши транспортные средства на улицах, расширяете ли вы новые рынки или разрабатываете наши приложения, вы станете частью динамичной и страстной команды, которая формирует город будущего.',
    'About11Text3': 'Ознакомьтесь с нашими текущими вакансиями и подайте заявку немедленно.',
    'About11Btn': 'Посмотреть пост',
    'About12Title': 'HRT доступен в более чем 100 городах по всей стране',
    'About12Country1': 'Австрия',
    'About12Country1Town1': 'Вена',
    'About12Country2': 'Бельгия',
    'About12Country2Town1': 'Брюссель',
    'About12Country3': 'Дания',
    'About12Country3Town1': 'Орбург',
    'About12Country3Town2': 'Орхус',
    'About12Country3Town3': 'Оденсе',
    'About12Country4': 'Финляндия',
    'About12Country4Town1': 'Хельсинки',
    'About12Country4Town2': 'Ювяскюле',
    'About12Country4Town3': 'Тампере',
    'About12Country4Town4': 'Турку',
    'About12Country5': 'Франция',
    'About12Country5Town1': 'Марсель',
    'About12Country6': 'Германия',
    'About12Country6Town1': 'Ахен',
    'About12Country6Town2': 'Аугсбург',
    'About12Country6Town3': 'Берлин',
    'About12Country6Town4': 'Бохум',
    'About12Country6Town5': 'Бонн',
    'About12Country6Town6': 'Кёльн',
    'About12Country6Town7': 'Дортмунд',
    'About12Country6Town8': 'Дюссельдорф',
    'About12Country6Town9': 'Эссен',
    'About12Country6Town10': 'Франкфурт',
    'About12Country6Town11': 'Гамбург',
    'About12Country6Town12': 'Ганновер',
    'About12Country6Town13': 'Карлсруэ',
    'About12Country6Town14': 'Лейпциг',
    'About12Country6Town15': 'Любек',
    'About12Country6Town16': 'Майнц / Висбаден',
    'About12Country6Town17': 'Мюнстер',
    'About12Country6Town18': 'Мюнхен',
    'About12Country6Town19': 'Нюрнберг',
    'About12Country6Town20': 'Пфорцхайм',
    'About12Country6Town21': 'Росток',
    'About12Country6Town22': 'Штутгарт',
    'About12Country7': 'Италия',
    'About12Country7Town1': 'Милан',
    'About12Country7Town2': 'Модена',
    'About12Country7Town3': 'Реджо - Эмилия',
    'About12Country7Town4': 'Рош',
    'About12Country7Town5': 'Турин',
    'About12Country8': 'США',
    'About12Country8Town1': 'Лос - Анджелес',
    'About12Country8Town2': 'Сан - Франциско',
    'About12Country8Town3': 'Лас - Вегас',
    'About12Country8Town4': 'Окленд',
    'About12Country8Town5': 'Ванкувер',
    'About12Country8Town6': 'Сиэтл',
    'About12Country8Town7': 'Портленд',
    'About12Country8Town8': 'Денвер',
    'About12Country8Town9': 'Гонолулу',
    'About12Country8Town10': 'Фресно',
    'About12Country8Town11': 'Нью - Йорк',
    'About12Country8Town12': 'Вашингтон',
    'About12Country9': 'Испания',
    'About12Country9Town1': 'Малага',
    'About12Country9Town2': 'Севилья',
    'About12Country10': 'Швеция',
    'About12Country10Town1': 'Броз',
    'About12Country10Town2': 'Эскирстуна',
    'About12Country10Town3': 'Гётеборг',
    'About12Country10Town4': 'Хальмстад',
    'About12Country10Town5': 'Хельсингборг',
    'About12Country10Town6': 'Линь Сюэпин',
    'About12Country10Town7': 'Лунд',
    'About12Country10Town8': 'Мальмё',
    'About12Country10Town9': 'Норчёпинг',
    'About12Country10Town10': 'Эребру',
    'About12Country10Town11': 'Стокгольм',
    'About12Country10Town12': 'Уппсала',
    'About12Country10Town13': 'Вестерас',
    'About12Country11': 'Швейцария',
    'About12Country11Town1': 'Базель',
    'About12Country11Town2': 'Берн',
    'About12Country11Town3': 'Фраунфельд',
    'About12Country11Town4': 'Ильнау - Эфретикон',
    'About12Country11Town5': 'Винтертур',
    'About12Country11Town6': 'Цюрих',
    'About12Country12': 'Канада',
    'About12Country12Town1': 'Ванкувер',
    'About12Country12Town2': 'Калгари',
    'About12Country12Town3': 'Эдмонтон.',
    'About12Country12Town4': 'Галифакс',
    // 'About12Country12Town5': '凯特琳',
    // 'About12Country12Town6': '利物浦',
    // 'About12Country12Town7': '伦敦',
    // 'About12Country12Town8': '北安普顿',
    // 'About12Country12Town9': '牛津',
    // 'About12Country12Town10': '朴茨茅斯',
    // 'About12Country12Town11': '拉什登和海厄姆·费勒斯',
    // 'About12Country12Town12': '南安普顿',
    // 'About12Country12Town13': '威灵伯勒',
    'About13Title': 'Руководство',
    'About13Text1': 'Узнайте о некоторых членах руководящей команды HRT Technology, которые отвечают за разработку направлений для нашей компании, а также о некоторых региональных лидерах, которые применяют их на практике по всей территории Соединенных Штатов.',
    'About14Title': 'До сих пор наше путешествие',
    'About14Text1': '2018 год',
    'About14Text2': 'Компания HRT, основанная в Стокгольме Фредриком Хьельмом, Адамом Джафером, Филипом Линдваллом и Дугласом Старком, является ведущим европейским оператором электрических скутеров. С первого дня наша шведская традиция способствовала нашей приверженности безопасности дорожного движения, отличным парковочным решениям, эффективности работы и доступному и удобному транспорту для всех.',
    'About14Text3': '2019 год',
    'About14Text4': 'Компания HRT запустила электрические скутеры в более чем 40 европейских городах, и количество поездок на них достигло 1 миллиона на всех рынках. Чтобы повысить безопасность наездников, мы создали Академию RideSafe Academy (ранее известную как RideLikeVoila) - первую онлайн - школу транспорта для электрических скутеров. Чтобы еще больше улучшить наше превосходство в эксплуатации, мы внедрили сменные батареи, которые снижают потребление энергии и выбросы.',
    'About14Text5': '2020 год',
    'About14Text6': 'В 2020 году HRT выиграла ряд инновационных тендеров на электрические скутеры в нескольких европейских городах, в том числе получила эксклюзивные лицензии в большинстве городов, участвующих в пилотной программе электрических скутеров в Великобритании - укрепила наши позиции как надежного и надежного партнера для городов.',
    'About14Text7': 'Мы также внедрили ряд функций внутренней безопасности, таких как низкоскоростной режим, тест на ночную езду и HRT Pass - модель ценообразования на основе подписки, которая делает использование HRT более доступным в качестве повседневного вида транспорта.',
    'About14Text8': '2021 год',
    'About14Text9': 'Компания HRT расширила ассортимент мобильных устройств, представив первый электрический велосипед. Чтобы продолжить инновации, мы представили новое поколение электрического скутера Voiager 4 с указателем поворота для повышения безопасности и улучшения геолокации, чтобы обеспечить более точные решения для парковки.',
    'About14Text10': 'За кулисами мы постоянно совершенствуем наши передовые операционные технологии с помощью программного обеспечения для оптимизации парка, поддерживаемого прогнозированием потребностей в режиме реального времени и машинным обучением. Это гарантирует максимальное использование наших транспортных средств и наилучшую доступность для пассажиров, что является ключевым фактором в нашей превосходной работе.',
    'About14Text11': '2022 год',
    'About14Text12': 'В 2022 году HRT достигла важной вехи в 100 миллионов поездок и запустила свой 100 - й город. Мы также представили Voiager 5 следующего поколения, самый безопасный и долговечный электрический скутер на сегодняшний день. Voiager 5 имеет высокомодульную конструкцию, упрощающую техническое обслуживание и облегчающую повторное использование деталей, долговечную и срок службы автомобиля до пяти лет.',
    'About14Text13': 'Мы продолжаем продвигать в Европе функции продукта HRT Parking Assistant, которые помогают пользователям правильно парковаться и вознаграждают за хорошую парковку, а также наказывают за повторяющиеся плохие парковки. Кроме того, HRT смогла выиграть повторные торги, например, во Франции и Дании, что продемонстрировало доверие к HRT со стороны городов повторных торгов.',
    'About15Title': 'Наше путешествие только начинается.',
    'About15Text1': 'Следите за нашим блогом, чтобы узнать последние новости и мнения о HRT Technology.',
    'About15Btn': 'Читайте наш блог',
    'CitiesOneTitle1': 'Ваш город, наш',
    'CitiesOneTitle2': 'Обязательства',
    'CitiesOneText1': 'Совместно с городскими чиновниками и организациями HRT решает проблемы городов, предлагая индивидуальные решения для удовлетворения конкретных транспортных потребностей и проблем в вашем регионе.',
    'CitiesTwoTitle': 'Безопасный, устойчивый и надежный микротранспорт для всех',
    'CitiesTwoText1': 'Мы предлагаем различные модели легких транспортных средств, которые безопасны, доступны по цене и просты в использовании, чтобы люди могли путешествовать по городу по своему усмотрению.',
    'CitiesTwoText2': 'Снижая зависимость от автомобилей и улучшая возможности для устойчивого передвижения, мы можем укрепить сеть общественного транспорта, чтобы помочь создать лучшие, более безопасные и пригодные для жизни города для всех.',
    'CitiesThreeTitle': 'Решение проблем безопасности и парковки',
    'CitiesThreeText1': 'В HRT, вдохновленные американской традицией и подходом « Нулевого видения», мы привержены безопасности. Это означает, что приоритет отдается безопасности всех участников дорожного движения, а не только наших всадников. Мы используем передовые технологии и предлагаем широкий спектр функций для повышения безопасности.',
    'CitiesThreeText2': 'Например, наша технология позволяет нам предотвращать езду на тротуарах и пьяную езду и ограничивать скорость транспортных средств в определенных районах. Что еще более важно, наши передовые в отрасли решения для парковки решают одну из ключевых проблем безопасности пешеходов и уязвимых участников дорожного движения: блокирование дорог.',
    'CitiesFourTitle': 'Ни один город и ни один город не являются идентичными.',
    'CitiesFourText1': 'Мы знаем, что у каждого города есть свои уникальные проблемы и возможности, и мы стремимся предлагать индивидуальные решения в области безопасности для решения этих проблем и возможностей. С крупнейшим лицензированным и тендерным парком электрических скутеров в Европе мы знаем, как решить проблемы безопасности и парковки.',
    'CitiesFourText2': 'Например, в некоторых районах мы работаем с муниципалитетами над установкой физических парковочных мест и покраской парковочных мест, чтобы эффективно решать проблемы парковки. В других районах мы используем технологию географического ограждения, чтобы предотвратить парковку в определенных местах. Мы также предлагаем функцию « Помощник парковки», которая вознаграждает пользователей за правильную парковку и наказывает за повторяющееся плохое поведение парковки.',
    'CitiesFourText3': 'Наша цель - работать с каждым городом, чтобы создать индивидуальные решения для решения конкретных проблем безопасности в его регионе. Мы считаем, что подход, основанный на приоритете городов, является ключом к созданию безопасных и устойчивых транспортных возможностей для всех.',
    'CitiesFourBtn': 'Наш метод',
    'CitiesFiveTitle': 'Решение проблемы первого и последнего километров',
    'CitiesFiveText1': 'HRT предлагает эффективный способ решения проблем первой и последней мили и улучшения удобства городского общественного транспорта. Например, теперь 55% всадников обычно сочетают поездки на электрических скутерах с общественным транспортом (согласно нашему опросу).',
    'CitiesFiveText2': 'Наши фреймворки в режиме реального времени используют стандартные интерфейсы программирования приложений (API) для общих операторов путешествий, такие как GBFS и MDS, чтобы наши услуги были легко интегрированы с общественным транспортом.',
    'CitiesSixTitle': 'Использование данных для улучшения транспортных маршрутов',
    'CitiesSixText1': 'Собирая сложные данные из наших сервисов и представляя их в понятной форме, мы можем помочь городским чиновникам получить полное представление об общих поездках.',
    'CitiesSixText2': 'Например, мы можем предложить тепловые карты для анализа наиболее загруженных районов города, а затем для оптимизации автобусных маршрутов и выявления мест, требующих дополнительного покрытия.',
    'CitiesSevenTitle': 'Ресурсы, необходимые для успеха',
    'CitiesSevenText1': 'Доступ к данным имеет решающее значение для успеха городов в совместном использовании микротранспорта. Мы используем сложившуюся практику обмена данными и настраиваем панели для предоставления информации в режиме реального времени, режима использования, соответствия правилам и т. Д. По мере необходимости.',
    'CitiesSevenText2': 'Эта прозрачность и усилия нашей преданной местной команды по обслуживанию и управлению нашим флотом и наземными операциями обеспечивают высокое качество и управляемость наших услуг.',
    'CitiesEightTitle': 'Предоставляя альтернативные виды транспорта и повышая доступность общественного транспорта, микротранспорт может помочь городам достичь своих климатических целей.',
    'SafeOneTitle': 'Наша приверженность безопасности',
    'SafeOneText': 'HRT использует основанный на данных и научно - исследовательский подход к созданию самых безопасных сервисов для микропередвижения по улицам, и наши меры безопасности играют важную роль.',
    'SafeTwoTitle': 'Содействие обеспечению транспарентности в области безопасности',
    'SafeTwoText1': 'Наши отчеты по безопасности преследуют двойную цель: не только способствовать подотчетности, но и позволить нам использовать наши знания для сокращения числа несчастных случаев и создания более безопасных общественных мест для всех.',
    'SafeTwoText2': 'Повышая прозрачность наших инициатив в области безопасности и подчеркивая шаги, которые мы предпринимаем для достижения цели « Нулевого видения», мы хотим укрепить доверие к нашим усилиям по улучшению безопасности.',
    'SafeTwoBtn': 'Загрузить отчет',
    'SafeThreeTitle': 'Подход HRT « Нулевое видение» фокусируется на трех ключевых областях',
    'SafeThreeSTitle1': 'Образование',
    'SafeThreeText1': 'Содействие безопасной езде на велосипеде и использование передовых технологий для защиты пешеходов',
    'SafeThreeSTitle2': 'Аппаратные средства',
    'SafeThreeText2': 'Создание наиболее безопасных транспортных средств и принятие активных мер по предотвращению аварий.',
    'SafeThreeSTitle3': 'Сотрудничество',
    'SafeThreeText3': 'Сотрудничество с городами для улучшения инфраструктуры и создания более безопасных улиц.',
    'SafeFourTitle': 'Содействие безопасной езде на велосипеде',
    'SafeFourText1': 'Важно предоставить нашим пользователям знания и навыки безопасной езды.',
    'SafeFourText2': 'Для достижения этой цели мы постоянно проводим обучение правилам дорожного движения и безопасному проезду через онлайн - платформу обучения RideSafe Academy, цифровое образование и мероприятия по обеспечению безопасности на местах.',
    'SafeFiveTitle': 'Защита пешеходов',
    'SafeFiveText1': 'Мы используем передовые технологии для обеспечения безопасности пешеходов и других уязвимых участников дорожного движения, предлагая ряд функций для повышения безопасности.',
    'SafeFiveText2': 'Например, наша технология позволяет нам предотвращать езду на тротуарах и пьяную езду и ограничивать скорость транспортных средств в определенных районах. Кроме того, наше ведущее в отрасли решение для парковки решает одну из основных проблем безопасности пешеходов и уязвимых участников дорожного движения: блокирование дорог.',
    'SafeSixTitle': 'Ни один город и ни один город не являются идентичными.',
    'SafeSixText1': 'В HRT мы знаем, что каждый город имеет свои уникальные проблемы и возможности, и мы стремимся предоставить индивидуальные решения безопасности для решения этих проблем и возможностей. С крупнейшим лицензированным и тендерным парком электрических скутеров в Европе мы знаем, как решить проблемы безопасности и парковки.',
    'SafeSixText2': 'Например, в некоторых районах мы работаем с муниципалитетами над установкой физических парковочных мест и покраской парковочных мест, чтобы эффективно решать проблемы парковки. В других районах мы используем технологию географического ограждения, чтобы предотвратить парковку в определенных местах. Мы также предлагаем функцию « Помощник парковки», которая вознаграждает пользователей за правильную парковку и наказывает за повторяющееся плохое поведение парковки.',
    'SafeSixText3': 'Наша цель - работать с каждым городом, чтобы создать индивидуальные решения для решения конкретных проблем безопасности в его регионе. Мы считаем, что этот подход является ключом к созданию безопасных и устойчивых транспортных вариантов для всех.',
    'SafeSevenTitle': 'Дизайн более безопасного путешествия.',
    'SafeSevenText1': 'Каждое новое поколение транспортных средств оснащено различными улучшениями безопасности, которые отвечают уникальным вызовам и потребностям совместной индустрии микромобильности.',
    'SafeSevenText2': 'Например, в качестве прямого ответа на предпочтения гонщиков и города мы внедрили ряд функций безопасности, таких как торможение обеих рук, поворотные огни и большие размеры колес.',
    'SafeSevenBtn': 'Наши машины.',
    'SafeEightTitle': 'Обеспечить безопасность автомобиля в любое время',
    'SafeEightText1': 'Наши транспортные средства оснащены несколькими уникальными датчиками, которые постоянно контролируют состояние парка и принимают активные меры для предотвращения аварий. Мы регулярно проводим инспекции, включая проверку качества на месте и ремонт на месте, чтобы свести к минимуму транспортировку на склад.',
    'SafeEightText2': 'Наши датчики также могут определить, перевернулся ли один из наших автомобилей. Эта информация, а также отзывы от нашего инструмента отчетности « Report a HRT » отправляются непосредственно нашей местной команде на месте, что позволяет нам быстро действовать, чтобы обеспечить безопасность всех людей на улицах.',
    'SafeNineTitle': 'Руководство по совершенствованию городской инфраструктуры',
    'SafeNineText1': 'Инфраструктура является одним из важнейших факторов обеспечения безопасности микропутешествий. Используя данные наших транспортных средств и обратную связь с пассажирами, мы можем помочь городам улучшить городскую инфраструктуру в соответствии с потребностями граждан в режиме реального времени, выявляя горячие точки аварии и районы с ухудшающимися дорожными условиями.',
    'ParkHeaderTitle': 'Решение парковки',
    'ParkHeaderText': 'Микространспорт стал эффективным решением для снижения дорожных заторов. Тем не менее крайне важно обеспечить ответственную практику парковки, с тем чтобы не допустить перегрузки в других районах.',
    'ParkOneTitle': 'Важность правильной парковки',
    'ParkOneText1': 'Как ответственный поставщик микротранспорта, мы признаем, что транспортные средства HRT занимают городское пространство, и мы несем ответственность за то, чтобы пользователи парковались ответственно и правильно.',
    'ParkOneText2': 'Соблюдение правил парковки является важной частью наших обязательств по обеспечению безопасности и играет ключевую роль в обеспечении безопасности пассажиров и пешеходов, особенно для уязвимых групп населения с ограниченной мобильностью и плохим зрением.',
    'ParkTwoTitle': 'Наши решения доказали свою эффективность.',
    'ParkTwoText1': 'Наш подход к решению проблем парковки уникален в отрасли, и наши решения доказали свою эффективность.',
    'ParkTwoText2': 'Это подтверждается, например, исследованием, проведенным Норвежским институтом экономики транспорта для совместного использования решений для парковки электрических скутеров.',
    'ParkTwoBtn': 'Читать исследование',
    'ParkThreeTitle': 'Ни один город и ни один город не являются идентичными.',
    'ParkThreeText1': 'Мы знаем, что каждый город имеет свои уникальные проблемы и возможности, поэтому мы предлагаем индивидуальные решения для решения этих проблем и возможностей. Благодаря высокоточным технологическим решениям, высокоточным GPS, эффективной эксплуатации и другим целевым мерам для обеспечения упорядоченной, безопасной и законной парковки.',
    'ParkFourTitle': 'Узнайте о некоторых из наших передовых парковочных решений',
    'ParkFiveTitle': 'Специальные парковочные места',
    'ParkFiveText1': 'Выделение специальных парковочных мест для совместного использования электрических скутеров является очень эффективным решением. Установив установленные запретные зоны для парковки в приложении для электрических скутеров и используя программное обеспечение, предназначенное для предотвращения того, чтобы гонщики заканчивали свое путешествие за пределами безопасного места для парковки, можно побудить гонщиков искать такие решения.',
    'ParkFiveText2': 'Мы работаем с городом, чтобы тщательно спланировать предложение и расположение парковочных зон в зоне эксплуатации. Оптимальный дизайн парковки в разных городах сильно варьируется в зависимости от таких факторов, как район эксплуатации, плотность города и доступная инфраструктура.',
    'ParkFiveText3': 'Однако некоторые аспекты остаются последовательными, включая:',
    'ParkFiveText4': 'При размещении обязательных парковочных мест (например, в густонаселенных городских центрах) они должны быть физически узнаваемыми.',
    'ParkFiveText5': 'Плотность обязательных парковочных мест должна составлять не менее 30 на квадратный километр, чтобы обеспечить достаточную доступность.',
    'ParkFiveText6': 'Для обеспечения бесперебойного движения автотранспорта должно быть доступно не менее 3: 1 на одно автотранспортное средство.',
    'ParkFiveText7': 'Пожалуйста, прочтите нас.',
    'ParkFiveText8': 'Деловые советы',
    'ParkFiveText9': 'Чтобы узнать больше.',
    'ParkSixTitle': 'Технология для всех',
    'ParkSixText1': 'Наши транспортные средства оснащены несколькими современными датчиками, и при использовании в сочетании с самыми современными технологиями компьютерного зрения и искусственного интеллекта мы можем автоматически оповещать местную операционную команду HRT в случае неправильной парковки.',
    'ParkSixText2': 'Используя эту технологию и данные, мы можем быстро исправить любые проблемы с парковкой и опередить потенциальные проблемы.',
    'ParkSevenTitle': 'Помогите пользователям остановиться',
    'ParkSevenText1': 'Стимулирование парковки способствует улучшению парковки, предоставляя скидки на парковку в определенных районах. Кроме того, наши « парковочные помощники» могут помочь пользователям правильно припарковаться и вознаграждать за хорошую парковку, а также наказывать за повторяющиеся плохие действия по парковке.',
    'ParkSevenText2': 'В конце каждой поездки пассажир должен представить фотографию парковки, чтобы подчеркнуть важность правильной парковки. Эта функция также дает обратную связь пассажирам в случае неправильной парковки, обеспечивая своевременное и эффективное решение любых проблем.',
    'ParkEightTitle': 'Коллективные усилия',
    'ParkEightText1': 'Хотя плохое парковка ограничивается небольшой группой пассажиров, одно неправильно припаркованное транспортное средство может препятствовать проезду. Нашей первоочередной задачей является разработка первоклассных парковочных решений и других мер безопасности. Наш опыт показывает, что тесное сотрудничество между операторами и поставщиками электрических скутеров имеет решающее значение для достижения этой цели.',
    'MobilityHeaderTitle': 'Мобильные партнеры',
    'MobilityHeaderText1': 'Давайте работать вместе, чтобы достичь завтрашнего мобильного путешествия.',
    'MobilityOneTitle': 'Наши партнеры по путешествиям имеют решающее значение для реализации нашего видения безшумных, экологически чистых и пригодных для жизни городов.',
    'MobilityOneText1': 'С первого дня наша шведская традиция вдохновляет нас на то, чтобы сделать транспорт доступным, удобным и устойчивым для всех. Мы сотрудничаем с многочисленными органами общественного транспорта и поставщиками транспортных услуг по всей Европе, чтобы все граждане имели доступ к таким услугам.',
    'MobilityOneText2': 'Наши интегрированные решения предоставляют технологии, которые объединяют их, позволяя гражданам выбирать вид транспорта или комбинацию различных способов для удовлетворения своих транспортных потребностей, создавая тем самым индивидуальные решения для каждой поездки. Таким образом, HRT и его партнеры повышают гибкость выбора, продвигают более экологически чистые способы путешествий и меняют социальные способы передвижения.',
    'MobilityTwoTitle': 'Партнерские решения HRT',
    'MobilityTwoText1': 'Наша платформа для использования в режиме реального времени использует стандартный API для общих операторов мобильности, что позволяет любому партнеру и платформе легко интегрироваться с нашими услугами.',
    'MobilityTwoTitle1': 'Легкая интеграция',
    'MobilityTwoText2': 'Наша легкая интеграция позволяет обмениваться информацией о наших транспортных средствах в режиме реального времени, например, о местоположении, доступности, ценах и географических зонах, где действуют правила, касающиеся скорости, парковки и запретных зон. Легкая интеграция позволит пользователям найти общие варианты микромобильности, доступные поблизости. Забронирование транспортных средств, платежи и обслуживание клиентов будут обрабатываться HRT.',
    'MobilityTwoText3': 'В соответствии с потребностями партнеров я использую GBFS (General Bike Share Profission Terminal) и MDS (Mobile Data Terminal) для достижения легкой интеграции.',
    'MobilityTwoTitle2': 'Глубокая интеграция',
    'MobilityTwoText4': 'Наша глубокая интеграция позволяет партнерам предоставлять полный пользовательский интерфейс HRT через свои платформы. Благодаря единому интерфейсу, обеспечивающему поддержку процессов бронирования и оплаты для всех видов транспорта, используемых в путешествиях, таких как автобусы, поезда и HRT, удобство интермодальных перевозок, связанных с общественным транспортом, повышается.',
    'MobilityTwoText5': 'Для глубокой интеграции HRT разработала стандартную платформу MaaS (MicroMobile как услуга), которая позволяет легко интегрировать полные сервисы HRT.',
    'MobilityTwoTitle3': 'Физическая интеграция: мобильный центр',
    'MobilityTwoText6': 'Мы также работаем с партнерами по общественному транспорту и городами, чтобы поддержать создание мобильных узлов. Мобильные узлы дают людям возможность оставить свои автомобили дома и наслаждаться бесшовным путешествием с использованием различных видов транспорта, одновременно улучшая порядок и удобство вокруг транспортных узлов.',
    'MobilityTwoText7': 'Например, HRT может предоставить выделенные парковочные места, физическую инфраструктуру парковки и анализ режимов использования.',
    'MobilityThreeTitle': 'Тематическое исследование: интеграция HRT с общественным транспортом в Штутгарте',
    'MobilityThreeText1': 'Экспериментальная инициатива в Штутгарте показывает, что интеграция совместного микротранспорта в местную систему общественного транспорта может помочь увеличить пассажиропоток общественного транспорта. В проекте участвуют HRT, а также местный железнодорожный оператор S - Bahn в Штутгарте и провайдер платформы MaaS Mobimeo.',
    'MobilityThreeText2': 'Сервис электрических скутеров HRT доступен для бронирования через приложение Mobility Stuttgart, которое соединяет общие поездки с общественным транспортом с помощью транспортного планировщика, что облегчает пользователям планирование поездок. Кроме того, пользователи могут получать купоны при комбинировании различных видов транспорта, а также парковочные места для электрических скутеров на железнодорожном вокзале.',
    'MobilityThreeText3': 'Исследование показало, что количество поездок на общих электрических скутерах в течение пилотного периода увеличилось на 250%, а продажи железнодорожных билетов выросли на 35%. Инициатива также показала, что физическая парковочная инфраструктура железнодорожного вокзала может уменьшить хаос, вызванный электрическими скутерами.',
    'MobilityThreeBtn': 'Читать тематические исследования',
    'MobilityFourTitle': 'Хотите сотрудничать с HRT?',
    'MobilityFourText1': 'Хотите узнать больше о интегрированных микромобильных решениях HRT или обсудить идею? Свяжитесь с нами, чтобы начать диалог или получить доступ к нашим партнерским документам.',
    'MobilityFourBtn': 'Свяжитесь с нами',
    'MobilityFiveTitle': 'Партнеры',
    'MobilitySixTitle': 'Давайте вместе создадим более устойчивую и эффективную транспортную систему в городах.',
    'SustainabilityHeaderTitle': 'Устойчивое развитие',
    'SustainabilityHeaderText': 'Наша приверженность климату и окружающей среде является основой всего бизнеса.',
    'SustainabilityOneTitle': 'Город, созданный для жизни, без шума и загрязнения',
    'SustainabilityOneText1': 'Микротранспорт может помочь обезуглероживать городской транспорт, предлагая альтернативы автомобилям и улучшая удобство общественного транспорта.',
    'SustainabilityOneText2': 'Мы принимаем целостный подход к решению наших экологических проблем и стремимся постоянно совершенствовать наши методы устойчивого развития.',
    'SustainabilityTwoTitle': 'Важные шаги на сегодняшний день',
    'SustainabilityTwoTitle1': 'Повышение мобильности',
    'SustainabilityTwoText1': 'Совместное использование микротранспорта является удобным дополнением к сетям общественного транспорта и расширяет доступ граждан к устойчивому транспорту. Например, согласно нашему опросу пользователей, 55% всадников обычно сочетают поездки на электрических скутерах с общественным транспортом.',
    'SustainabilityTwoTitle2': 'Новая технология батарей',
    'SustainabilityTwoText2': 'Заменяемые батареи значительно сокращают выбросы, потребление энергии и дорожные пробки. Аккумуляторы заменяются прямо на улице, просто перевозя использованные батареи для зарядки.',
    'SustainabilityTwoTitle3': 'Программа оборотных автотранспортных средств',
    'SustainabilityTwoText3': 'Благодаря нашей программе круговых транспортных средств нам удалось продлить срок службы нашего парка с менее чем одного года в 2018 году до среднего срока службы V5 в 2022 году (подтвержденного третьей стороной).',
    'SustainabilityThreeTitle': 'Мы постоянно работаем над сокращением выбросов, стремясь достичь положительного климата к 2030 году.',
    'SustainabilityFourTitle': 'Измерение выбросов',
    'SustainabilityFourText': 'Будучи компанией, приверженной подотчетности и прозрачности, мы являемся первым оператором электрических скутеров, который опубликовал всеобъемлющую оценку жизненного цикла обслуживания. Наша оценка жизненного цикла позволяет нам выявлять материалы, процессы производства и утилизации, которые приводят к выбросам, и соответствующим образом концентрировать наши усилия по сокращению выбросов.',
    'SustainabilityFiveTitle': 'Сокращение выбросов',
    'SustainabilityFiveText': 'Наш план действий в области окружающей среды нацелен на сокращение выбросов, уделяя особое внимание увеличению срока эксплуатации транспортных средств, переходу к эксплуатации с нулевым уровнем выбросов и внедрению практики циркуляции.',
    'SustainabilitySixTitle': 'Мы работаем над тем, чтобы сделать наши услуги полностью цикличными к 2025 году и достичь нулевого уровня отходов.',
    'SustainabilitySevenTitle': 'Переосмыслить',
    'SustainabilitySevenText': 'Совместное использование транспортных средств может быть более эффективным на протяжении всего их жизненного цикла, обеспечивая переход от ресурсосберегающих к низкоуглеродным поездкам. Мы постоянно внедряем инновации и развиваем наши услуги, чтобы расширить возможности для совместного путешествия.',
    'SustainabilityEightTitle': 'Восстановление',
    'SustainabilityEightText': 'У нас есть собственная команда разработчиков транспортных средств, которая работает в тесном контакте с поставщиками, чтобы изменить дизайн транспортных средств и свести к минимуму использование ресурсов. Мы увеличили срок службы скутеров до пяти лет и увеличили срок службы аккумуляторов с помощью умной зарядки.',
    'SustainabilityNineTitle': 'Техническое обслуживание',
    'SustainabilityNineText': 'Техническое обслуживание и ремонт являются важными компонентами продления срока службы автотранспортных средств. Мы работаем с обученными наемными работниками, которые внесли свой вклад в повышение коэффициента удержания запасных частей в Европе.',
    'SustainabilityTenTitle': 'Повторное использование',
    'SustainabilityTenText': 'Мы используем компоненты и запасные части как можно чаще. Например, почти все наши запасные части повторно используются для ремонта других транспортных средств или автостоянок. Пластик и резина были повторно использованы для тактильных полос на наших автостоянках и скотоводческих дворах.',
    'Sustainability11Title': 'Повторное использование',
    'Sustainability11Text': 'Мы восстанавливаем старые транспортные средства и перепродаем их другим компаниям, чтобы продлить их срок службы. Мы также работаем с партнерами, чтобы дать батареям вторую жизнь для хранения энергии.',
    'Sustainability12Title': 'Рекуперация',
    'Sustainability12Text': 'Когда повторное использование или повторное использование в цепочке создания стоимости HRT невозможны, материалы будут переработаны при поддержке наших партнеров по переработке. Все наши отходы перерабатываются через местных партнеров по переработке, и мы стремимся к тому, чтобы уровень захоронения отходов составлял 0%.',
    'Sustainability13Title': 'Сеть по вопросам устойчивого развития',
    'Sustainability14Title': 'Наша климатическая и социальная ответственность не ограничивается нами.',
    'Sustainability14Text': 'Мы работаем с поставщиками и партнерами, которые отдают приоритет ответственной практике.',
    'Sustainability14Btn': 'Наш кодекс поведения',
    'ModuleNewsTitle': 'Последние новости',
    'ModuleNewsText1': 'Важное обновление правил Malmö: что нужно знать гонщикам HRT',
    'ModuleNewsText2': 'HRT публикует ежегодный отчет по безопасности и обещает возглавить отчет по безопасности для достижения нулевого видения',
    'ModuleNewsText3': 'Использование HRT в США экономит доллары',
}